import { shorthands, Switch } from "@fluentui/react-components";
import { Info16Regular } from "@fluentui/react-icons";
import { observer } from "mobx-react-lite";
import { makeResponsiveStyles } from "../../../../../components/Responsive/makeResponsiveStyles";
import { updateIsPriorityJobAction } from "../../../actions/jobPriorityActions";
import { jobPriorityStore } from "../../../store/jobPriorityStore";
import { JobSetAsPriorityInputView } from "./JobSetAsPriorityInputView";

const useStyles = makeResponsiveStyles(
  {
    root: {
      display: "flex",
      flexDirection: "column",
      width: "100%",
      gap: "8px",
    },
    block: {
      display: "flex",
      padding: "16px",
      border: "1px solid #EDEBE9",
      borderRadius: "1px",
      flexDirection: "column",
      gap: "8px",
      flex: 1,
    },
  },
  {
    xs: {
      root: {
        ...shorthands.padding("20px", "24px", "24px", "24px"),
      },
    },
  },
);

export const JobSetAsPriorityView = observer(() => {
  const styles = useStyles();

  return (
    <div className={styles.root}>
      <div>
        <Switch
          checked={jobPriorityStore.isPriorityJob}
          onChange={() => {
            updateIsPriorityJobAction(!jobPriorityStore.isPriorityJob);
          }}
          label="Set as priority job"
        />
        <Info16Regular />
      </div>
      {jobPriorityStore.isPriorityJob && (
        <div className={styles.block}>
          <JobSetAsPriorityInputView />
        </div>
      )}
    </div>
  );
});

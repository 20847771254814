import type { TagPickerProps } from "@fluentui/react-components";
import {
  Avatar,
  Field,
  Link,
  shorthands,
  Tag,
  TagPicker,
  TagPickerControl,
  TagPickerGroup,
  TagPickerInput,
  TagPickerList,
  TagPickerOption,
  tokens,
} from "@fluentui/react-components";
import { computed } from "mobx";
import { observer } from "mobx-react-lite";
import { useEffect } from "react";
import { makeResponsiveStyles } from "../../../../../components/Responsive/makeResponsiveStyles";
import { updatePropValueActionV2 } from "../../../actions/jobActions";
import {
  getExpAgentPropNamePath,
  getExpAgentPropPath,
  getGPTAgentList,
} from "../../../helpers/GPTAgentHelper";
import { getValue } from "../../../selectors/getJobPropV2";
import { jobCreationStore } from "../../../store/jobCreationStore";

const useStyles = makeResponsiveStyles(
  {
    root: {
      backgroundColor: "white",
      boxSizing: "border-box",
      display: "flex",
      flexDirection: "column",
      ...shorthands.gap("20px"),
      ...shorthands.padding("24px", "87px", "24px", "87px"),
      ...shorthands.border("1px", "solid", "#EDEBE9"),
      ...shorthands.borderRadius("8px"),
    },
    experimentationFieldContainer: {
      display: "flex",
      flexDirection: "row",
      ...shorthands.gap("8px"),
      justifyContent: "space-between",
      alignItems: "center",
      width: "100%",
    },
    experimentationFieldTitle: {
      fontFamily: tokens.fontFamilyBase,
      fontSize: "14px",
      fontWeight: 400,
      lineHeight: "20px",
      width: "110px",
    },
  },
  {
    xs: {
      root: {
        ...shorthands.padding("24px", "24px", "24px", "24px"),
      },
    },
  },
);
interface JobGPTAgentViewProps {
  scope: "control" | "treatment";
}

const mockDeclarativeAgentsLink =
  "https://o365exchange.visualstudio.com/O365%20Core/_git/SydneyEvaluation?path=/Enterprise/src/CopilotPluginMocks/DeclarativeCopilots";

export const MockDeclarativeAgentsHint = (
  <div>
    For mock agent, ensure it is one of{" "}
    <Link href={mockDeclarativeAgentsLink}>
      the listed mock declarative agents{" "}
    </Link>{" "}
    and enable "sideload mock 3P plugins".
  </div>
);

export const JobGPTAgentView = observer((props: JobGPTAgentViewProps) => {
  const currentGPTPath = getExpAgentPropPath(props.scope);
  const currentGPTNamePath = getExpAgentPropNamePath(props.scope);
  const styles = useStyles();

  useEffect(() => {
    getGPTAgentList();
  }, []);

  const availableGPTAgents = jobCreationStore.availableGPTAgents;
  const currentGPTId = getValue(currentGPTPath);
  const currentGPTName = getValue(currentGPTNamePath);
  const selectedOption = computed(() => {
    if (currentGPTId) {
      return [currentGPTId];
    }
    if (currentGPTName) {
      return [currentGPTName];
    }
    return [];
  }).get();

  const hasCustomizedGPT =
    currentGPTName !== undefined && currentGPTName !== "";

  const onOptionSelect: TagPickerProps["onOptionSelect"] = (e, data) => {
    if (data.value === "mock-agent" || data.value === "no-options") {
      return;
    }
    const selectedOne = data.value;
    updatePropValueActionV2({
      prop: currentGPTNamePath,
      newData: undefined,
    });
    if (selectedOne === selectedOption[0]) {
      updatePropValueActionV2({
        prop: currentGPTPath,
        newData: "",
      });

      return;
    }

    updatePropValueActionV2({
      prop: currentGPTPath,
      newData: selectedOne,
    });
  };

  const tagPickerOptions = availableGPTAgents.filter(
    (option) => option.gptId !== currentGPTId,
  );

  if (currentGPTId === undefined && currentGPTName === undefined) {
    return <></>;
  }
  return (
    <div className={styles.experimentationFieldContainer}>
      <span className={styles.experimentationFieldTitle}>GPT Agent:</span>
      <Field
        style={{ flex: 1 }}
        validationState="warning"
        validationMessage={
          hasCustomizedGPT ? (
            <>
              If it's the real agent, please install in advance.
              <br />
              {MockDeclarativeAgentsHint}
            </>
          ) : undefined
        }
      >
        <TagPicker
          onOptionSelect={onOptionSelect}
          selectedOptions={selectedOption}
        >
          <TagPickerControl>
            <TagPickerGroup aria-label="Select Installed GPT Agent">
              {selectedOption.map((option) => (
                <Tag
                  key={option}
                  shape="rounded"
                  media={<Avatar aria-hidden name={option} color="colorful" />}
                  value={option}
                >
                  {availableGPTAgents.find((gpt) => gpt.gptId === option)
                    ?.name ?? `Mock Agent:${selectedOption}`}
                </Tag>
              ))}
            </TagPickerGroup>
            <TagPickerInput
              aria-label="Select Installed GPT Agent"
              onKeyDown={(ev) => {
                if (ev.key === "Enter") {
                  const agentName = ev.currentTarget.value;
                  const isInstalledAgent = availableGPTAgents.find(
                    (agent) => agent.name === agentName,
                  );
                  if (isInstalledAgent) {
                    updatePropValueActionV2({
                      prop: currentGPTPath,
                      newData: isInstalledAgent.gptId,
                    });
                    updatePropValueActionV2({
                      prop: currentGPTNamePath,
                      newData: undefined,
                    });
                  } else {
                    updatePropValueActionV2({
                      prop: currentGPTNamePath,
                      newData: agentName,
                    });
                    updatePropValueActionV2({
                      prop: currentGPTPath,
                      newData: undefined,
                    });
                  }
                }
              }}
            />
          </TagPickerControl>
          <TagPickerList>
            <TagPickerOption value="mock-agent">
              Input Mock Declarative Agent Name, end with "Enter" button...
            </TagPickerOption>
            {tagPickerOptions.length > 0 ? (
              tagPickerOptions.map((option) => (
                <TagPickerOption
                  media={
                    <Avatar
                      aria-hidden
                      shape="square"
                      name={option.name ?? "Unknown"}
                      color="colorful"
                    />
                  }
                  value={option.gptId ?? "Unknown"}
                  key={option.gptId ?? "Unknown"}
                >
                  {option.name ?? "Unknown"}
                </TagPickerOption>
              ))
            ) : (
              <TagPickerOption value="no-options">
                No GPT agent installed found, please refresh the page.
              </TagPickerOption>
            )}
          </TagPickerList>
        </TagPicker>
      </Field>
    </div>
  );
});

import { createStore } from "satcheljs";
import type { PrivacyFilterTemplateContentArray } from "sydneyeval-shared";
import type { GeneralMetricsResponse } from "../models/GeneralMetricsResponse";
import type { LMChecklistAssertionsResponse } from "../models/LMChecklistAssertionsResponse";
import type { LMChecklistDetailsResponse } from "../models/LMChecklistDetailsResponse";
import type { LMChecklistIcmsResponse } from "../models/LMChecklistIcmsResponse";
import type { MChatSValueResponse } from "../models/UtteranceResponse";

export const defaultUttsPerPage = 20;

export interface MChatUtteranceMetricsSingleData {
  key: string;
  control: number;
  treatment: number;
}
export interface MChatUtteranceMetrics {
  segment: string;
  query_hash: string;
  query: string;
  metrics: MChatUtteranceMetricsSingleData[];
}

interface IJobDeatilStore {
  filters: string[];
  utterNum: number;
  expandedIndex: number | undefined;
  generalMetricsResponse: GeneralMetricsResponse | undefined;
  // NEW Updated
  metricsToDisplay: string[];
  selectedMChatMetrics: string[];
  debuglinkList: MChatSValueResponse;
  annotationDebuglinkList: MChatSValueResponse;
  utteranceList: Map<string, MChatUtteranceMetrics>;
  utteranceMetricDetail: Map<string, MChatUtteranceMetricsSingleData[]>;
  lmchecklistDetailsResponse: LMChecklistDetailsResponse | undefined;
  lmchecklistAssertionsResponse: LMChecklistAssertionsResponse | undefined;
  lmchecklistIcmsResponse: LMChecklistIcmsResponse | undefined;
  privacyFilterResponse: PrivacyFilterTemplateContentArray | undefined;
}

export const initJobDetailStore: IJobDeatilStore = {
  filters: [],
  utterNum: defaultUttsPerPage,
  expandedIndex: undefined,
  generalMetricsResponse: undefined,
  metricsToDisplay: [],
  selectedMChatMetrics: [],
  debuglinkList: [],
  annotationDebuglinkList: [],
  utteranceList: new Map<string, MChatUtteranceMetrics>(),
  utteranceMetricDetail: new Map<string, MChatUtteranceMetricsSingleData[]>(),
  lmchecklistDetailsResponse: undefined,
  lmchecklistAssertionsResponse: undefined,
  lmchecklistIcmsResponse: undefined,
  privacyFilterResponse: undefined,
};

export const jobDetailStore = createStore<IJobDeatilStore>(
  "jobDetailStore",
  Object.assign({}, initJobDetailStore),
)();

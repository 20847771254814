import { orchestrator } from "satcheljs";
import {
  openJobSetAsPriorityDialogAction,
  resetJobPriorityStateAction,
  updateIsPriorityJobAction,
  updateJobPriorityADOLinkAction,
  updateJobPriorityADOLinkValidationStatusAction,
  updateJobPriorityDescriptionAction,
} from "../actions/jobPriorityActions";
import {
  openJobSetAsPriorityDialog,
  resetJobPriorityState,
  updateIsPriorityJob,
  updateJobPriorityADOLink,
  updateJobPriorityADOLinkValidationStatus,
  updateJobPriorityDescription,
} from "../mutators/jobPriorityMutators";

orchestrator(updateJobPriorityADOLinkAction, ({ newLink }) => {
  // Update prop in configure
  updateJobPriorityADOLink(newLink);
});

orchestrator(updateJobPriorityDescriptionAction, ({ newDescription }) => {
  // Update prop in configure
  updateJobPriorityDescription(newDescription);
});

orchestrator(openJobSetAsPriorityDialogAction, ({ currentJobId }) => {
  openJobSetAsPriorityDialog(true, currentJobId);
});

orchestrator(updateIsPriorityJobAction, ({ isPriorityJob }) => {
  updateIsPriorityJob(isPriorityJob);
});

orchestrator(resetJobPriorityStateAction, () => {
  resetJobPriorityState();
});

orchestrator(updateJobPriorityADOLinkValidationStatusAction, ({ status }) => {
  updateJobPriorityADOLinkValidationStatus(status);
});

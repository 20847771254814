import {
  bool,
  JobPermissionInfo,
  num,
  object,
  optional,
  str,
} from "sydneyeval-shared";

export const Job = object({
  ID: num,
  JobId: optional(str),
  JobName: optional(str),
  Status: optional(str),
  StudioUrl: optional(str),
  CreatedBy: optional(str),
  CreatedAt: optional(str),
  Settings: optional(str),
  Notes: optional(str),
  LastUpdateTime: optional(str),
  JobTemplateId: optional(num),
  JobType: optional(str),
  JobTemplateType: optional(str),
  Log: optional(str),
  ExperimentName: optional(str),
  HasMetrics: optional(bool),
  IsProductionTemplate: optional(bool),
  Message: optional(str),
  CreatorSmtpAddress: optional(str),
  DataSets: optional(str),
  StartTime: optional(str),
  EndTime: optional(str),
  EnvironmentId: optional(num),
  TemplateBuildVersion: optional(str),
  IsPriorityJob: optional(bool),
  PriorityJobADOLink: optional(str),
  Properties: optional(
    object({
      IsOwner: optional(bool),
      IsBingJob: optional(bool),
      HasReadPermission: optional(bool),
      HasSharePermission: optional(bool),
      IsTestTenant: optional(bool),
    }),
  ),
  Metadata: optional(
    object({
      jobPermissionInfo: optional(JobPermissionInfo),
    }),
  ),
});

export type Job = ReturnType<typeof Job>;

export const CreatedJobResponse = object({
  InsertedID: num,
});

export type CreatedJobResponse = ReturnType<typeof CreatedJobResponse>;

export const UpdateJobPriorityResponse = object({
  message: str,
});

export const ValidateADOLinkResponse = object({
  status: str,
});

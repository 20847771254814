import { resultStore } from "../partners/OfflineEval/store/resultStore";
import { getUtteranceConversationId } from "./apiHelper";
import { telemetryHelper } from "./telemetryHelper";
export type DevUILink = {
  conversationId: string;
  timeStamp: string;
  link: string;
  isNew: boolean;
};

export const openDevUILink = (
  queryStr: string,
  queryHash: string,
  jobId: string,
  exp_name: string,
): Promise<DevUILink> => {
  const isOwner =
    resultStore.resultJob?.Metadata?.jobPermissionInfo?.isScrapingOwner ??
    false;
  return getUtteranceConversationId({
    JobId: jobId,
    Query: queryStr,
    QueryHash: queryHash,
    ExperimentName: exp_name,
  }).then((data) => {
    if (data.length === 0) {
      telemetryHelper.logDiagnosticEvent("SydneyResponse", {
        JobId: jobId,
        message: "Trigger V1 API: Failed",
      });
      throw new Error("No conversation record found.");
    }
    const conversationId = data[0].conversationId;

    const timeStamp = data[0].time;

    const requestId = data[0].requestId;

    const isV1 = data[0].version === 1;

    if (!isV1) {
      const host = window.location.hostname;
      const link = isOwner
        ? `https://aka.ms/CopilotDevUI?sourceConversationId=${conversationId}`
        : requestId !== undefined
        ? `https://aka.ms/CopilotDevUI?sevalConversationId=${conversationId}&host=${host}&requestId=${requestId}`
        : `https://aka.ms/CopilotDevUI?sevalConversationId=${conversationId}&host=${host}`;

      const hasFile = data[0].filePath !== undefined;
      if (isOwner || hasFile) {
        telemetryHelper.logDiagnosticEvent("SydneyResponse", {
          JobId: jobId,
          message: "Success",
        });
        return {
          conversationId,
          timeStamp,
          link,
          isNew: true,
        };
      } else {
        telemetryHelper.logDiagnosticEvent("SydneyResponse", {
          JobId: jobId,
          message: "Fail to get file path",
        });
      }
    }

    const link = `https://www.aka.ms/bizchatdevui?sourceConversationId=${conversationId}&timeStamp=${timeStamp}&clientName=seval`;

    return {
      conversationId,
      timeStamp,
      link,
      isNew: false,
    };
  });
};

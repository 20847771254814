import {
  Accordion,
  AccordionHeader,
  AccordionItem,
  AccordionPanel,
  Link,
  ProgressBar,
  Spinner,
  Toast,
  ToastBody,
  ToastFooter,
  ToastTitle,
  ToastTrigger,
  Toaster,
  makeStyles,
  mergeClasses,
  useId,
  useToastController,
} from "@fluentui/react-components";
import { CheckmarkCircle20Filled } from "@fluentui/react-icons";
import { observer } from "mobx-react-lite";
import React, { useEffect } from "react";
import { SharedToasterPrefix } from "../../../../../components/Wrappers/ResponsiveProvider";
import { telemetryHelper } from "../../../../../helpers/telemetryHelper";
import { isFeatureEnabled } from "../../../../../selectors/features";
import { contributionStore } from "../../../../../store/contributionStore";
import { executeJobPreVerification } from "../../../actions/jobActions";
import {
  updateJobPreValidationIsCopyLinkClicked,
  updateJobPreValidationIsDispatched,
  updateJobPreValidationResolve,
} from "../../../mutators/jobPreValidationMutators";
import {
  getJobPreValidationIsCopyLinkClicked,
  getJobPreValidationIsDispatched,
  getJobPreValidationIsFinishedVerifing,
  getJobPreValidationProgressbarValue,
  getJobPreValidationSteps,
  getJobPreValidationToastBody,
  getJobPreValidationToastTitle,
} from "../../../selectors/getJobPreValidation";
import { jobPreValidationStore } from "../../../store/jobPreValidationStore";

const useStyles = makeStyles({
  row: {
    width: "100%",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },
  clamp: {
    display: "-webkit-box",
    WebkitLineClamp: 3,
    WebkitBoxOrient: "vertical",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
  toastfooter: {
    display: "flex",
    alignItems: "flex-start",
    gap: "14px",
    flexDirection: "column",
  },
});

export const JobPreValidationToast = observer(() => {
  const styles = useStyles();
  const toasterId = useId(SharedToasterPrefix, "JobPreValidationToaster");
  const toastId = useId("JobPreValidationToast");
  const { dispatchToast, updateToast, dismissToast } =
    useToastController(toasterId);
  const isCopyLinkClicked = getJobPreValidationIsCopyLinkClicked.get();
  const jobPreValidationSteps = getJobPreValidationSteps.get();
  const toastTitle = getJobPreValidationToastTitle.get();
  const toastBody = getJobPreValidationToastBody.get();
  const progressbarValue = getJobPreValidationProgressbarValue.get();
  const isDispatched = getJobPreValidationIsDispatched.get();
  const isFinishedVerifing = getJobPreValidationIsFinishedVerifing.get();
  const rowclamp = mergeClasses(styles.row, styles.clamp);

  const clickDismiss = () => {
    jobPreValidationStore.JobPreValidationResolve?.(false);
    updateJobPreValidationResolve(undefined);
  };

  const renderFailedToast = () => (
    <Toast>
      <ToastTitle>verification failed</ToastTitle>
      <ToastBody style={{ gridColumn: "1/3" }}>
        <Accordion collapsible={true}>
          {jobPreValidationSteps &&
            jobPreValidationSteps.length > 0 &&
            jobPreValidationSteps.map((step, index) => (
              <AccordionItem
                style={{ wordBreak: "break-all" }}
                key={index}
                value={index}
              >
                <AccordionHeader>
                  {step.stepName} {step.status}
                </AccordionHeader>
                <AccordionPanel>
                  <div className={rowclamp}>
                    {step.results.map((result, resultIndex) => (
                      <React.Fragment key={resultIndex}>
                        {`user: ${result.scrap_user}, status code: ${result.statusCode}, response: ${result.responseBody}`}
                        <br />
                      </React.Fragment>
                    ))}
                  </div>

                  <div className={styles.row}>
                    <Link
                      onClick={() => {
                        const results = step.results
                          .map(
                            (result) =>
                              `user: ${result.scrap_user}, status code: ${result.statusCode}, response: ${result.responseBody}`,
                          )
                          .join("\n");
                        navigator.clipboard.writeText(results);
                        updateJobPreValidationIsCopyLinkClicked(
                          isCopyLinkClicked.map((_clicked, i) => i === index),
                        );
                      }}
                    >
                      Copy full text
                    </Link>
                    {isCopyLinkClicked[index] && (
                      <CheckmarkCircle20Filled primaryFill={"#26a03b"} />
                    )}
                  </div>
                </AccordionPanel>
              </AccordionItem>
            ))}
        </Accordion>
      </ToastBody>
      <ToastFooter className={styles.toastfooter}>
        <Link
          href="https://eng.ms/docs/experiences-devices/m365-core/microsoft-search-assistants-intelligence-msai/msai-stca/sydney-evaluation-platform/copilot-evaluation-platform/seval-manual-book/setup-job/bizchat-job-setup/debug-the-pre-submit-validation-failure"
          target="_blank"
        >
          How to debug the Pre Submit Validation Failure
        </Link>
        {isFeatureEnabled("bypass-jobprevalidation") && (
          <Link
            onClick={() => {
              telemetryHelper.logUserActionEvent("PreSubmitJobSkip");
              jobPreValidationStore.JobPreValidationResolve?.(true);
              updateJobPreValidationResolve(undefined);
            }}
          >
            Skip and continue submit the job
          </Link>
        )}
        <ToastTrigger>
          <Link
            onClick={() => {
              updateJobPreValidationIsDispatched(false);
              clickDismiss();
            }}
          >
            Close and confirm the job settings
          </Link>
        </ToastTrigger>
      </ToastFooter>
    </Toast>
  );

  const validationEnd = () => {
    //check if all the step in jobPreValidationStore.jobPreValidationSteps are passed
    const allStepsPassed = jobPreValidationSteps.every(
      (step) => step.statusCode === 200,
    );
    const filteredJobPreValidationSteps = jobPreValidationSteps.map(
      ({ statusCode, sydney_url, stepName, exp_config, results }) => ({
        statusCode,
        sydney_url,
        stepName,
        exp_config,
        results: results.map(
          ({
            statusCode: resultStatusCode,
            client_request_id,
            responseBody,
          }) => ({
            statusCode: resultStatusCode,
            client_request_id,
            responseBody: resultStatusCode !== 200 ? responseBody : undefined,
          }),
        ),
      }),
    );
    if (!allStepsPassed) {
      telemetryHelper.logUserActionEvent("PreSubmitJobFailure", {
        JobSteps: filteredJobPreValidationSteps,
      });
      updateToast({
        toastId,
        content: renderFailedToast(),
        timeout: -1,
        intent: "warning",
      });
    } else {
      telemetryHelper.logUserActionEvent("PreSubmitJobSuccess", {
        JobSteps: filteredJobPreValidationSteps,
      });
      jobPreValidationStore.JobPreValidationResolve?.(true);
      updateJobPreValidationResolve(undefined);
    }
  };

  React.useEffect(() => {
    if (isCopyLinkClicked.includes(true)) {
      updateToast({
        toastId,
        content: renderFailedToast(),
        timeout: -1,
        intent: "warning",
      });
    }
  }, [isCopyLinkClicked]);

  React.useEffect(() => {
    if (isFinishedVerifing === true) {
      validationEnd();
    }
  }, [isFinishedVerifing]);

  React.useEffect(() => {
    if (progressbarValue >= 0) {
      updateToast({
        toastId,
        content: renderToast(),
      });
    }
  }, [progressbarValue]);

  const renderToast = () => (
    <Toast>
      <ToastTitle media={<Spinner size="tiny" />}>{toastTitle}</ToastTitle>
      <ToastBody>
        <div style={{ marginBottom: "5px" }}>{toastBody}</div>
        <ProgressBar value={progressbarValue} max={100} />
      </ToastBody>
    </Toast>
  );
  const notify = () => {
    if (isDispatched) {
      updateToast({
        toastId,
        content: renderToast(),
      });
      return;
    }
    dispatchToast(renderToast(), {
      timeout: -1,
      toastId,
    });
  };

  const location = contributionStore.currentPath;
  useEffect(() => {
    if (location !== "/create") {
      updateJobPreValidationResolve(undefined);
    }
  }, [location]);

  useEffect(() => {
    if (jobPreValidationStore.JobPreValidationResolve !== undefined) {
      telemetryHelper.logUserActionEvent("PreSubmitJob");
      executeJobPreVerification();
      notify();
      updateJobPreValidationIsDispatched(true);
    } else {
      dismissToast(toastId);
      updateJobPreValidationIsDispatched(false);
    }
  }, [jobPreValidationStore.JobPreValidationResolve]);

  return <Toaster toasterId={toasterId} />;
});

import {
  Button,
  Dialog,
  DialogActions,
  DialogBody,
  DialogContent,
  DialogSurface,
  DialogTitle,
  Field,
  Input,
  Textarea,
} from "@fluentui/react-components";
import React, { useEffect } from "react";
import { getManagedIdentityToken } from "../../helpers/apiHelper";
import { getRefreshToken } from "../../helpers/tokenHelper";
import { TokenGenerationResponse } from "../../models/TokenGenerationResponse";
import { store } from "../../store/store";
import { msalInstance } from "../Wrappers/AuthProvider";

type TokenGeneratingDialogProps = {
  type:
    | "ManagedIdentityToken"
    | "UserAccessToken"
    | "UserAccessTokenPopup"
    | "UserRefreshToken";
  isOpen: boolean;
  onComplete: () => void;
};

export const TokenGeneratingDialog = (props: TokenGeneratingDialogProps) => {
  const [scope, setScope] = React.useState<string>("");
  const [token, setToken] = React.useState<string>("");
  const [result, setResult] = React.useState<string>("");
  const [redirectUri, setRedirectUri] = React.useState<string | undefined>(
    undefined,
  );

  const getTitle = () => {
    switch (props.type) {
      case "ManagedIdentityToken":
        return "Generate Managed Identity Token";
      case "UserAccessToken":
        return "Generate User Access Token";
      case "UserAccessTokenPopup":
        return "Generate User Access Token (Popup)";
      case "UserRefreshToken":
        return "Generate User Refresh Token";
    }
  };

  const getPromise = () => {
    switch (props.type) {
      case "ManagedIdentityToken":
        return getManagedIdentityToken(scope);
      case "UserAccessToken":
        return msalInstance.acquireTokenSilent({
          scopes: [scope],
          account: store.account,
          redirectUri,
        });
      case "UserAccessTokenPopup":
        return msalInstance.acquireTokenPopup({
          scopes: [scope],
          account: store.account,
          redirectUri,
          prompt: "select_account",
        });
      case "UserRefreshToken":
        return Promise.resolve({
          token: getRefreshToken(),
        });
    }
  };

  const shouldShowRedirectUri = () => {
    switch (props.type) {
      case "ManagedIdentityToken":
        return false;
      case "UserAccessToken":
        return true;
      case "UserAccessTokenPopup":
        return true;
      case "UserRefreshToken":
        return false;
    }
  };

  const shouldShowScope = () => {
    switch (props.type) {
      case "ManagedIdentityToken":
        return true;
      case "UserAccessToken":
        return true;
      case "UserAccessTokenPopup":
        return true;
      case "UserRefreshToken":
        return false;
    }
  };

  const isGetTokenEnabled = () => {
    switch (props.type) {
      case "ManagedIdentityToken":
        return scope.trim() !== "";
      case "UserAccessToken":
        return scope.trim() !== "";
      case "UserAccessTokenPopup":
        return scope.trim() !== "";
      case "UserRefreshToken":
        return true;
    }
  };

  useEffect(() => {
    const getDefaultScope = () => {
      switch (props.type) {
        case "ManagedIdentityToken":
          return "3e9313d0-debc-4f0d-85a7-171f92333ba1";
        case "UserAccessToken":
          return "https://substrate.office.com/.default";
        case "UserAccessTokenPopup":
          return "https://substrate.office.com/.default";
        case "UserRefreshToken":
          return "";
      }
    };

    setScope(getDefaultScope());
    setToken("");
    setResult("");
  }, [props.isOpen]);

  return (
    <Dialog
      open={props.isOpen}
      onOpenChange={() => {
        props.onComplete();
      }}
    >
      <DialogSurface>
        <DialogBody>
          <DialogTitle>{getTitle()}</DialogTitle>
          <DialogContent>
            {shouldShowRedirectUri() && (
              <Field label={"Redirect Uri"}>
                <Input
                  style={{ width: "100%" }}
                  size="medium"
                  value={redirectUri}
                  onChange={(_, data) => {
                    if (data.value === "") {
                      setRedirectUri(undefined);
                    } else {
                      setRedirectUri(data.value);
                    }
                  }}
                />
              </Field>
            )}

            {shouldShowScope() && (
              <Field required label={"Scope"}>
                <Input
                  style={{ width: "100%" }}
                  size="medium"
                  value={scope}
                  onChange={(_, data) => {
                    setScope(data.value);
                  }}
                />
              </Field>
            )}

            <Field label={"Token"}>
              <Textarea
                style={{ width: "100%" }}
                size="medium"
                value={token}
                onChange={(_, data) => {
                  setToken(data.value);
                }}
              />
            </Field>

            <Field label={"Result"}>
              <Textarea
                style={{ width: "100%" }}
                size="medium"
                value={result}
                onChange={(_, data) => {
                  setResult(data.value);
                }}
              />
            </Field>
          </DialogContent>

          <DialogActions>
            <Button
              disabled={!isGetTokenEnabled()}
              appearance="primary"
              onClick={() => {
                setResult("");
                setToken("");
                getPromise()
                  .then((response) => {
                    const responseString = JSON.stringify(response);
                    setResult(responseString);

                    const tokenResponse = TokenGenerationResponse(
                      response,
                      "TokenGenerationResponse",
                    );
                    setToken(
                      tokenResponse?.accessToken ?? tokenResponse?.token ?? "",
                    );
                  })
                  .catch((error) => {
                    setResult(error.message);
                  });
              }}
            >
              Get Token
            </Button>
          </DialogActions>
        </DialogBody>
      </DialogSurface>
    </Dialog>
  );
};

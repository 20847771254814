import { getFeatureFlagsJSON } from "../selectors/features";
import {
  getAPIAccessToken,
  getCsrfToken,
  getGraphAccessToken,
} from "./accessTokenHelper";

export const getBasicHeader = async () => {
  const [csrfToken, apiToken, graphToken] = await Promise.all([
    getCsrfToken(),
    getAPIAccessToken(),
    getGraphAccessToken(),
  ]);
  return {
    "Content-Type": "application/json",
    "X-XSRF-TOKEN": csrfToken,
    "X-GRAPH-TOKEN": graphToken,
    "x-data-feature-flags": getFeatureFlagsJSON(),
    Authorization: `Bearer ${apiToken}`,
  };
};

export const getBasicHeaderWithoutAuth = async () => {
  const csrfToken = await getCsrfToken();
  return {
    "Content-Type": "application/json",
    "X-XSRF-TOKEN": csrfToken,
  };
};

import {
  makeStyles,
  mergeClasses,
  shorthands,
} from "@fluentui/react-components";
import { computed } from "mobx";
import { observer } from "mobx-react-lite";
import React, { createContext } from "react";
import { FeatureContributionPageSize } from "../../contribution/FeatureContribution";
import { telemetryHelper } from "../../helpers/telemetryHelper";
import { contributionStore } from "../../store/contributionStore";
import type { WrapperProps } from "./WrapperProps";

const ScrollContext = createContext<{
  canShowTips: boolean;
  isTipTriggered: () => void;
}>({
  canShowTips: false,
  isTipTriggered: () => {
    telemetryHelper.logErrorEvent("UIError", {
      message: "ScrollContext is not initialized",
    });
  },
});

export const useScrollContext = () => {
  return React.useContext(ScrollContext);
};

const useStyles = makeStyles({
  mainViewScrollContainer: {
    height: "100%",
    width: "100%",
    display: "flex",
    flexDirection: "column",
    boxSizing: "border-box",
    scrollbarGutter: "stable",
    ...shorthands.padding("20px"),
    ...shorthands.overflow("auto", "auto"),
    flexGrow: 1,
  },
  noPadding: {
    ...shorthands.padding("20px", 0, 0, 0),
    ...shorthands.overflow("hidden", "hidden"),
    scrollbarGutter: "none",
  },
  smallPadding: {
    ...shorthands.padding("20px"),
  },
});

export const ScrollContainer = observer((props: WrapperProps) => {
  const styles = useStyles();
  const [canShowTips, setCanShowTips] = React.useState(false);
  const paddingStyle = computed(() => {
    switch (contributionStore.selectedContributionPathRegistry?.pageSize) {
      case FeatureContributionPageSize.ThroughPage:
        return styles.noPadding;
      default:
        return styles.smallPadding;
    }
  });

  React.useEffect(() => {
    const handleScroll = () => {
      setCanShowTips(false);
    };
    const scrollableDiv = document.querySelector("#mainViewScrollContainer");
    scrollableDiv?.addEventListener("scroll", handleScroll);

    return () => {
      scrollableDiv?.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <ScrollContext.Provider
      value={{
        canShowTips: canShowTips,
        isTipTriggered: () => {
          setCanShowTips(true);
        },
      }}
    >
      <div
        id="mainViewScrollContainer"
        className={mergeClasses(
          styles.mainViewScrollContainer,
          paddingStyle.get(),
        )}
      >
        {props.children}
      </div>
    </ScrollContext.Provider>
  );
});

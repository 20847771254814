import { Link } from "@fluentui/react-components";
import { observer } from "mobx-react-lite";
import { StatusTile } from "../../../../components/Shared/StatusTile";
import { updateCurrentPath } from "../../../../mutators/updateContributions";
import { onScheduleJobAction } from "../../actions/scheduleJobActions";
import { formatCreateTime } from "../../helpers/formatHelper";
import { loadMoreDisplayJobs } from "../../mutators/scheduleJobMutators";
import { scheduleJobStore } from "../../store/scheduleJobStore";
import { GeneralTable } from "../JobList/GeneralTable";
import { SchedulerActionButton } from "./components/SchedulerActionButton";
import { TextCell } from "./components/TextCell";
import { sharedStyles } from "./sharedStyles";

export const ActiveScheduleJobsTable = observer(() => {
  const { activeScheduleJobs } = scheduleJobStore;

  const styles = sharedStyles();
  return (
    <GeneralTable
      loadMore={() => loadMoreDisplayJobs()}
      hasMore={
        scheduleJobStore.displayJobListLength < activeScheduleJobs.length
      }
      rows={activeScheduleJobs.slice(0, scheduleJobStore.displayJobListLength)}
      columns={[
        {
          title: "Scheduler Name",
          render: (job) => (
            <div>
              <Link
                onClick={() => {
                  onScheduleJobAction(job, "ReadOnly");

                  updateCurrentPath(`/scheduler/list/${job.Id}`);
                }}
              >
                {job.Name}
              </Link>
            </div>
          ),
          weight: 4,
          minWidth: "400px",
        },
        {
          title: "Operation",
          render: (scheduler) => (
            <div className={styles.actionButtons}>
              <SchedulerActionButton scheduler={scheduler} action="Edit" />
              <SchedulerActionButton scheduler={scheduler} action="Stop" />
              <SchedulerActionButton scheduler={scheduler} action="Enable" />
            </div>
          ),
          weight: 1.1,
          minWidth: "50px",
        },
        {
          title: "Template",
          render: (job) => <TextCell text={job.TemplateName ?? ""} />,
          weight: 2,
          minWidth: "150px",
        },
        {
          title: "Create By",
          render: (job) => <TextCell text={job.CreatedBy ?? ""} />,
          weight: 1,
          minWidth: "50px",
        },
        {
          title: "Create At",
          render: (job) => <TextCell text={formatCreateTime(job.CreatedAt)} />,
          weight: 1,
          minWidth: "50px",
        },
        {
          title: "Status",
          render: (job) => (
            <StatusTile hasClickEvent={false} status={job.Status} />
          ),
          weight: 1,
          minWidth: "50px",
        },
      ]}
    />
  );
});

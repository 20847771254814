import {
  Breadcrumb,
  BreadcrumbDivider,
  BreadcrumbItem,
  Link as FluentLink,
  makeStyles,
} from "@fluentui/react-components";
import React from "react";
import { Link } from "react-router-dom";

const useStyles = makeStyles({
  root: {
    position: "absolute",
    zIndex: 99,
    left: 0,
    right: 0,
    marginTop: "-20px",
    width: "100%",
    backgroundColor: "#fff",
    padding: "4px 16px",
    borderBottom: "1px solid #D1D1D1",
  },
});

export const ShadowABBreadcrumb = (props: {
  nav: Array<{ link?: string; text: string }>;
}) => {
  const { nav } = props;
  const styles = useStyles();
  return (
    <Breadcrumb className={styles.root}>
      <BreadcrumbItem key="Shadow AB">
        <Link to={"/shadowab"}>
          <FluentLink>{"Shadow AB"}</FluentLink>
        </Link>
      </BreadcrumbItem>
      {nav.map((item, index) => {
        return (
          <React.Fragment key={index}>
            <BreadcrumbDivider style={{ margin: "0 8px" }} />
            <BreadcrumbItem>
              {item.link ? (
                <Link to={item.link}>
                  <FluentLink>{item.text}</FluentLink>
                </Link>
              ) : (
                item.text
              )}
            </BreadcrumbItem>
          </React.Fragment>
        );
      })}
    </Breadcrumb>
  );
};

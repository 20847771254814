import { str } from "sydneyeval-shared";
import {
  apiRequest,
  avalonRequest,
  graphRequest,
  substrateRequest,
} from "../authConfig";
import { msalInstance } from "../components/Wrappers/AuthProvider";
import {
  updateIsSettingAvalonAccessToken,
  updateIsSettingHeronAccessToken,
  updateIsSettingSubstrateToken,
  updateTokenResolver,
} from "../mutators/updateDevelopStatus";
import { store } from "../store/store";
import { getAppEnv } from "./appEnvHelper";
import { cachable } from "./cachable";
import {
  getCachedAvalonAccessToken,
  getCachedHerontAccessToken,
  getCachedSubstrateToken,
  setCachedAvalonAccessToken,
  setCachedHeronAccessToken,
  setCachedSubstrateToken,
} from "./cacheHelper";
import { createRequest } from "./createRequest";

export const getAPIAccessToken = () =>
  msalInstance
    .acquireTokenSilent({ ...apiRequest, account: store.account })
    .then((response) => response.accessToken);

export const getGraphAccessToken = () =>
  msalInstance
    .acquireTokenSilent({ ...graphRequest, account: store.account })
    .then((response) => response.accessToken);

export const getHeronAccessToken = () => {
  const appEnv = getAppEnv();

  switch (appEnv.type) {
    case "LOCALHOST":
      return getLocalHeronAccessToken();
    case "STAGING":
    case "PROD":
      return Promise.resolve(undefined);
  }
};

export const getSubstrateToken = () => {
  const appEnv = getAppEnv();

  switch (appEnv.type) {
    case "LOCALHOST":
      return getLocalSubstrateToken();
    case "STAGING":
    case "PROD":
      return msalInstance
        .acquireTokenSilent({ ...substrateRequest, account: store.account })
        .then((response) => response.accessToken);
  }
};

export const getAvalonAccessToken = () => {
  if (store.account === undefined) {
    return Promise.resolve(undefined);
  }

  const appEnv = getAppEnv();
  switch (appEnv.type) {
    case "LOCALHOST":
      return getLocalAvalonAccessToken();
    case "STAGING":
    case "PROD":
      return msalInstance
        .acquireTokenSilent({
          ...avalonRequest,
          account: store.account,
        })
        .then((_) => _.accessToken);
  }
};

const getCsrfHeader = () => {
  return {
    "Content-Type": "application/json",
  };
};

let cachedCsrfToken: string | undefined = undefined;

export const clearCsrfToken = () => {
  cachedCsrfToken = undefined;
};

export const getCsrfToken = () => {
  if (cachedCsrfToken) {
    return Promise.resolve(cachedCsrfToken);
  }

  return createRequest({
    api: "/getCsrfToken",
    typeGuard: str,
    headers: getCsrfHeader(),
    dataBag: { skipTelemetry: true },
  }).then((token) => {
    cachedCsrfToken = token;
    return token;
  });
};

// Cachable

export const {
  get: getLocalSubstrateToken,
  cleanOnError: cleanLocalSubstrateTokenOnError,
} = cachable(
  () => {
    const cachedSubstrateToken = getCachedSubstrateToken();
    if (cachedSubstrateToken) {
      return Promise.resolve(cachedSubstrateToken);
    }

    updateIsSettingSubstrateToken(true);
    return new Promise<string>((resolve) => {
      updateTokenResolver(resolve);
    });
  },
  () => {
    setCachedSubstrateToken(undefined);
  },
);

export const {
  get: getLocalAvalonAccessToken,
  cleanOnError: cleanLocalAvalonAccessTokenOnError,
} = cachable(
  () => {
    const cachedAvalonAccessToken = getCachedAvalonAccessToken();
    if (cachedAvalonAccessToken) {
      return Promise.resolve(cachedAvalonAccessToken);
    }

    updateIsSettingAvalonAccessToken(true);
    return new Promise<string>((resolve) => {
      updateTokenResolver(resolve);
    });
  },
  () => {
    setCachedAvalonAccessToken(undefined);
  },
);

export const {
  get: getLocalHeronAccessToken,
  cleanOnError: cleanLocalHeronAccessTokenOnError,
} = cachable(
  () => {
    const cachedHeronAccessToken = getCachedHerontAccessToken();
    if (cachedHeronAccessToken) {
      return Promise.resolve(cachedHeronAccessToken);
    }

    updateIsSettingHeronAccessToken(true);
    return new Promise<string>((resolve) => {
      updateTokenResolver(resolve);
    });
  },
  () => {
    setCachedHeronAccessToken(undefined);
  },
);

import { action } from "satcheljs";
import type { ADOLinkValidationStatus } from "../store/jobPriorityStore";

export const updateJobPriorityADOLinkAction = action(
  "updateJobPriorityADOLinkAction",
  (newLink: string) => ({
    newLink,
  }),
);

export const updateJobPriorityDescriptionAction = action(
  "updateJobPriorityDescriptionAction",
  (newDescription: string) => ({
    newDescription,
  }),
);

export const openJobSetAsPriorityDialogAction = action(
  "openJobSetAsPriorityDialogAction",
  (currentJobId: number | undefined) => ({
    currentJobId,
  }),
);

export const resetJobPriorityStateAction = action(
  "resetJobPriorityStateAction",
);

export const updateIsPriorityJobAction = action(
  "updateIsPriorityJob",
  (isPriorityJob: boolean) => ({
    isPriorityJob,
  }),
);

export const updateJobPriorityADOLinkValidationStatusAction = action(
  "updateJobPriorityADOLinkValidationStatusAction",
  (status: ADOLinkValidationStatus | undefined) => ({
    status,
  }),
);

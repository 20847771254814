import { Tag, makeStyles } from "@fluentui/react-components";
import { computed } from "mobx";
import { observer } from "mobx-react-lite";

const useStyles = makeStyles({
  Running: {
    color: "#115EA3",
    border: "1px solid #B4D6FA",
    backgroundColor: "#EBF3FC",
  },
  Completed: {
    color: "#0E700E",
    border: "1px solid #9FD89F",
    backgroundColor: "#F1FAF1",
  },
  Waiting: {
    color: "#8A3707",
    border: "1px solid #FDCFB4",
    backgroundColor: "#FFF9F5",
  },
  Pending: {
    color: "#616161",
    border: "1px solid #E0E0E0",
    backgroundColor: "#FFFFFF",
  },
  Cancelled: {
    color: "#616161",
    border: "1px solid #E0E0E0",
    backgroundColor: "#F0F0F0",
  },
  Failed: {
    color: "#B10E1C",
    border: "1px solid #EEACB2",
    backgroundColor: "#FDF3F4",
  },
});
interface IStatusTagProps {
  label: string;
}
export const StatusTag = observer((props: IStatusTagProps) => {
  const { label } = props;
  const styles = useStyles();
  const tagStyle = computed(() => {
    switch (label) {
      case "Pending":
        return styles.Pending;
      case "In queue":
        return styles.Waiting;
      case "Running":
        return styles.Running;
      case "Completed":
        return styles.Completed;
      case "Failed":
        return styles.Failed;
      default:
        return styles.Cancelled;
    }
  });
  return (
    <Tag appearance="filled" size="small" className={tagStyle.get()}>
      {label}
    </Tag>
  );
});

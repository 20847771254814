import { array, num, object, str } from "sydneyeval-shared";

export const LMChecklistIcm = object({
  IncidentId: num,
  Assertion: str,
  AssertionHash: str,
  Query: str,
  QueryHash: str,
});

export const LMChecklistIcmsResponse = array(LMChecklistIcm);

export type LMChecklistIcmsResponse = ReturnType<
  typeof LMChecklistIcmsResponse
>;
export type LMChecklistIcm = ReturnType<typeof LMChecklistIcm>;

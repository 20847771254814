import type { TooltipProps } from "@fluentui/react-components";
import { Button, Tooltip, makeStyles } from "@fluentui/react-components";
import { Info16Regular } from "@fluentui/react-icons";
import { observer } from "mobx-react-lite";
import React from "react";
import { useScrollContext } from "../Wrappers/ScrollerContainer";
import type { WrapperProps } from "../Wrappers/WrapperProps";

type TipProps = WrapperProps & TooltipProps;

const useStyles = makeStyles({
  tip: {
    maxHeight: "300px",
    wordBreak: "break-word",
    overflowY: "auto",
  },
});

export const Tip = observer((props: TipProps) => {
  const [isVisible, setIsVisible] = React.useState(false);
  const scrollContext = useScrollContext();
  const styles = useStyles();

  const { content } = props;

  React.useEffect(() => {
    if (!scrollContext.canShowTips) {
      setIsVisible(false);
    }
  }, [scrollContext.canShowTips]);

  if (typeof content === "string") {
    const scrollContent = () => {
      return <div className={styles.tip}>{content}</div>;
    };
    return (
      <Tooltip
        {...props}
        content={scrollContent()}
        onVisibleChange={(_, data) => {
          if (data.visible) {
            scrollContext.isTipTriggered();
          }
          setIsVisible(data.visible ?? false);
        }}
        visible={isVisible}
        withArrow
      >
        {props.children}
      </Tooltip>
    );
  }

  return (
    <Tooltip
      {...props}
      onVisibleChange={(_, data) => {
        if (data.visible) {
          scrollContext.isTipTriggered();
        }
        setIsVisible(data.visible ?? false);
      }}
      visible={isVisible}
      withArrow
    >
      {props.children}
    </Tooltip>
  );
});

type IInforIconTipProps = {
  content: string;
};

export const InforIconTip = observer((props: IInforIconTipProps) => {
  return (
    <Tip content={props.content} relationship="label">
      <Button icon={<Info16Regular />} appearance="transparent" />
    </Tip>
  );
});

import { JobConfiguration, parseJsonStrWithDefault } from "sydneyeval-shared";
import type { Job } from "../../../partners/OfflineEval/models/Job";

export const getNexusUrlByJobSettings = (job: Job) => {
  try {
    const jobSettingObject = parseJsonStrWithDefault(
      job.Settings,
      JobConfiguration,
      {},
    );
    const controlFlights =
      jobSettingObject?.configs_in_json?.conversations?.exp_configs?.find(
        (v) => v.exp_name === "control",
      )?.sydney?.variants;
    const treatmentFlight =
      jobSettingObject?.configs_in_json?.conversations?.exp_configs?.find(
        (v) => v.exp_name === "experiment",
      )?.sydney?.variants;
    const nexusParameters = {
      treatment_id: `ShadowAB_Treatment_DoubleForkControl_${
        job.JobName?.replace(/[^0-9|a-z|A-Z|.|\-|_]/g, "") ?? ""
      }`,
      control_id: `ShadowAB_Treatment_DoubleForkTreatment_${
        job.JobName?.replace(/[^0-9|a-z|A-Z|.|\-|_]/g, "") ?? ""
      }`,
      control_flights: controlFlights ?? "",
      treatment_flights: treatmentFlight ?? "",
    };
    return `https://copilotnexushub.azurewebsites.net/experiment-definition-generator?${new URLSearchParams(
      nexusParameters,
    ).toString()}`;
  } catch (_e) {
    return `https://copilotnexushub.azurewebsites.net/experiment-definition-generator`;
  }
};

"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.GPTAgentListResponse = exports.GPTAgent = exports.gptIdentifier = void 0;
const typeHelper_1 = require("../tools/typeHelper");
exports.gptIdentifier = (0, typeHelper_1.object)({
    id: (0, typeHelper_1.optional)(typeHelper_1.str),
    source: (0, typeHelper_1.optional)(typeHelper_1.str),
});
exports.GPTAgent = (0, typeHelper_1.object)({
    gptId: (0, typeHelper_1.optional)(typeHelper_1.str),
    gptIdentifier: (0, typeHelper_1.optional)(exports.gptIdentifier),
    type: (0, typeHelper_1.optional)(typeHelper_1.str),
    name: (0, typeHelper_1.optional)(typeHelper_1.str),
    metaOSAppId: (0, typeHelper_1.optional)(typeHelper_1.str),
    metaOSTitleId: (0, typeHelper_1.optional)(typeHelper_1.str),
});
exports.GPTAgentListResponse = (0, typeHelper_1.object)({
    gptList: (0, typeHelper_1.optional)((0, typeHelper_1.array)(exports.GPTAgent)),
});

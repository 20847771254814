import type { SinglePageFeatureContribution } from "../../contribution/FeatureContribution";
import { FeatureContributionPageSize } from "../../contribution/FeatureContribution";
import { isFeatureEnabled } from "../../selectors/features";
import { ExperimentContainer } from "./components/experiments/ExperimentContainer";
import { ExperimentScorecardDetail } from "./components/experiments/ExperimentScorecardDetail";

export const shadowABContribution: SinglePageFeatureContribution = {
  type: "single-page",
  name: "ShadowAB",
  isEnabled: () => isFeatureEnabled("shadowAB"),
  defaultPath: "/shadowab",
  pathRegistry: {
    "/shadowabscorecard/:scorecardId": {
      pageSize: FeatureContributionPageSize.FullPage,
      render: () => <ExperimentScorecardDetail />,
    },
    "/shadowab": {
      pageSize: FeatureContributionPageSize.ThroughPage,
      render: () => <ExperimentContainer />,
    },
  },
};

import { observer } from "mobx-react-lite";
import React from "react";
import type { WrapperProps } from "./WrapperProps";

type ConditionalWrapperProps<T> = WrapperProps & {
  condition: boolean | undefined;
  Wrapper: React.ComponentType<T & WrapperProps>;
  wrapperProps: T;
};

export const ConditionalWrapper = observer(
  <T extends object>(props: ConditionalWrapperProps<T>) => {
    const { condition, children, Wrapper, wrapperProps } = props;

    if (!condition) {
      return <>{children}</>;
    }

    return <Wrapper {...wrapperProps}>{children}</Wrapper>;
  },
);

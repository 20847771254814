import { mutatorAction } from "satcheljs";
import type { ScheduledJob } from "sydneyeval-shared";
import type { Job } from "../models/Job";
import { scheduleJobStore } from "../store/scheduleJobStore";

export const switchPageMode = mutatorAction(
  "switchPageMode",
  (mode: "View" | "Edit" | "Create" | "ReadOnly") => {
    scheduleJobStore.pageMode = mode;
  },
);

export const updateScheduledJobLoadStatus = mutatorAction(
  "updateScheduledJobLoadStatus",
  (isLoading: boolean) => {
    scheduleJobStore.isLoading = isLoading;
  },
);

export const updateActiveScheduleJobList = mutatorAction(
  "updateActiveScheduleJobList",
  (list: ScheduledJob[]) => {
    scheduleJobStore.activeScheduleJobs = list;
  },
);

export const updateSelectedScheduleJob = mutatorAction(
  "updateSelectedScheduleJob",
  (selected: ScheduledJob) => {
    scheduleJobStore.selectedScheduledJob = selected;
  },
);

export const updateErrorMessage = mutatorAction(
  "updateErrorMessage",
  (message: string | undefined) => {
    scheduleJobStore.errorMessage = message;
  },
);

export const loadMoreDisplayJobs = mutatorAction("loadMoreDisplayJobs", () => {
  const currentLength = scheduleJobStore.displayJobListLength;
  const maxLength = scheduleJobStore.activeScheduleJobs.length;
  scheduleJobStore.displayJobListLength = Math.min(
    maxLength,
    currentLength + 20,
  );
});

export const updateSearchCriteria = mutatorAction(
  "updateSearchCriteria",
  (
    key: "Product" | "Name" | "CreateBy" | "Status",
    value: string | undefined,
  ) => {
    if (value === undefined) {
      scheduleJobStore.searchCriteria.delete(key);
    } else {
      scheduleJobStore.searchCriteria.set(key, value);
    }
  },
);

export const clearSearchCriteria = mutatorAction("clearSearchCriteria", () => {
  scheduleJobStore.searchCriteria = new Map<string, string>();
  scheduleJobStore.searchCriteria.set("Status", "Active");
});

export const updateRecentCompletedJobs = mutatorAction(
  "updateRecentCompletedJobs",
  (jobs: Job[]) => {
    scheduleJobStore.recentCompletedJobs = jobs;
  },
);

export const updateOwnedSchedulerCount = mutatorAction(
  "updateOwnedSchedulerCount",
  (count: number) => {
    scheduleJobStore.ownedSchedulerCount = count;
  },
);

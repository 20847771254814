export const defaultAzureStorageUrlPathPrefix =
  "azureml://datastores/sevaldatastore/paths/queryset/";

export const deprecatedUrlPathPrefixForBing =
  "azureml://datastores/sevaldatastore/paths";

export const bingAzureStorageUrlRoot =
  "azureml://datastores/workspaceblobstore/paths/";

export const BingQuerySetGenerationRAIMetrics = [
  "content_harms_multiturn",
  "jailbreak_multiturn",
  "ipcopyright_multiturn",
  "dea_multiturn",
];

export const dashboard_BingSingleturn =
  "https://msit.powerbi.com/groups/13ff7aa6-b0fa-4250-aa91-fb38b1688d9e/reports/d4b8a189-0167-4999-95ef-511250e64727?ctid=72f988bf-86f1-41af-91ab-2d7cd011db47&pbi_source=linkShare&bookmarkGuid=db9a4b73-b0e4-487e-b887-c1bfd795d6ee";

export const dashboard_BingMultiturn =
  "https://msit.powerbi.com/groups/13ff7aa6-b0fa-4250-aa91-fb38b1688d9e/reports/d4b8a189-0167-4999-95ef-511250e64727?ctid=72f988bf-86f1-41af-91ab-2d7cd011db47&pbi_source=linkShare&bookmarkGuid=96fd530a-4fd1-4594-9f0c-059ff799a9ca";

export const dashboard_BingAllJobs =
  "https://msit.powerbi.com/groups/13ff7aa6-b0fa-4250-aa91-fb38b1688d9e/reports/d4b8a189-0167-4999-95ef-511250e64727/ReportSection53cf671ad9933d8881bc?ctid=72f988bf-86f1-41af-91ab-2d7cd011db47&experience=power-bi&bookmarkGuid=db9a4b73-b0e4-487e-b887-c1bfd795d6ee";

export const CWC_SkipScraping_AML_Storage_Prefix =
  "azureml://datastores/workspaceblobstore/paths/CWCSkipScraping/";

export const folderPath_bingJobConfig = "SevalPortal/jobs";
export const folderPath_CWCJobConfig = "SevalPortal/CWC/jobs";

export const folderPath_CWCQuerySet = "SevalPortal/CWC/queryset";

export const fileUrlPrefix_CWCQuerySet_new =
  bingAzureStorageUrlRoot + folderPath_CWCQuerySet;

export const folderPath_bingQueryGenerate = "SevalPortal/generation";

export const bingfile_DiagnosisFileName = "seval_sbs_metrics_score.json";

export const citeFileSuffix_baseline = "_conversations.tsv";
export const citeFileSuffix_treatment = "_treatment_conversations.tsv";

export const Exp_Workspace_Id = "f082eddd-3c30-4fa7-83e7-ae506a831a40";

export enum FeatureContributionPageSize {
  FullPage = "FullPage",
  Narrow = "Narrow",
  ThroughPage = "ThroughPage",
}

export type FeatureContributionPathRegistry = {
  render: () => JSX.Element;
  title?: () => string | undefined;
  pageSize?: FeatureContributionPageSize;
};

export type FeatureContributionSubTab = {
  name: string;
  icon: () => JSX.Element;
  isEnabled?: () => boolean;
} & FeatureContributionPath;

export type FeatureContributionSubTabLink = {
  name: string;
  link: string;
  additionalView?: () => JSX.Element;
};

export type FeatureContributionBase = {
  name: string;
  isEnabled: () => boolean;
};

export type FeatureContributionPath = {
  defaultPath: string;
  pathRegistry: Record<string, FeatureContributionPathRegistry>;
};

export type SubTabFeatureContribution = FeatureContributionBase & {
  type: "sub-tab";
  subTabs: FeatureContributionSubTab[];
  subTabLinks?: FeatureContributionSubTabLink[];
};

export type SinglePageFeatureContribution = FeatureContributionBase &
  FeatureContributionPath & {
    type: "single-page";
  };

export type FeatureContribution =
  | SinglePageFeatureContribution
  | SubTabFeatureContribution;

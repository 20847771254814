import {
  Field,
  Input,
  Label,
  shorthands,
  Textarea,
  tokens,
} from "@fluentui/react-components";
import { Warning32Filled } from "@fluentui/react-icons";
import { observer } from "mobx-react-lite";
import { makeResponsiveStyles } from "../../../../../components/Responsive/makeResponsiveStyles";
import {
  updateJobPriorityADOLinkAction,
  updateJobPriorityDescriptionAction,
} from "../../../actions/jobPriorityActions";
import type { ADOLinkValidationStatus } from "../../../store/jobPriorityStore";
import { jobPriorityStore } from "../../../store/jobPriorityStore";

const useStyles = makeResponsiveStyles(
  {
    root: {
      display: "flex",
      padding: "16px",
      border: "1px solid #EDEBE9",
      borderRadius: "1px",
      flexDirection: "column",
      gap: "8px",
      flex: 1,
    },
    warningBox: {
      display: "flex",
      flexDirection: "row",
      gap: "8px",
      alignItems: "top",
      backgroundColor: tokens.colorStatusWarningBackground1,
      padding: "8px",
      border: `1px solid ${tokens.colorStatusWarningBorder1}`,
      borderRadius: tokens.borderRadiusMedium,
    },
    warningIcon: {
      verticalAlign: "middle",
      color: tokens.colorStatusWarningForeground1,
    },
    blockTitle: {
      fontFamily: tokens.fontFamilyBase,
      fontSize: "16px",
      fontWeight: 600,
      lineHeight: "22px",
    },
  },
  {
    xs: {
      root: {
        ...shorthands.padding("24px", "24px", "24px", "24px"),
      },
    },
  },
);

export const JobSetAsPriorityInputView = observer(() => {
  const styles = useStyles();

  const toLabelStatus = (status: ADOLinkValidationStatus | undefined) => {
    switch (status) {
      case "valid":
        return "success";
      case "invalid":
        return "error";
      case "warning":
        return "warning";
      case "empty":
        return "error";
      case "pending":
        return "none";
      default:
        return "none";
    }
  };

  const toLabelMessage = (status: ADOLinkValidationStatus | undefined) => {
    switch (status) {
      case "valid":
        return "ADO link validation passed";
      case "invalid":
        return "The ADO link is invalid. Please ensure the link is valid and try again.";
      case "warning":
        return "Unable to validate the ADO link.";
      case "empty":
        return "Please attach the ADO work item for this evaluation and ensure it is tagged with the 'Heron' label.";
      case "pending":
        return "";
      default:
        return "";
    }
  };

  return (
    <>
      <div className={styles.warningBox}>
        <Warning32Filled className={styles.warningIcon} />
        <Label>
          Attention: Any issue a priority job encounters will be an
          <b> automatic Sev25</b> and handled by a dedicated DRI. Marking the
          job as "priority" will trigger an alert to{" "}
          <b>[Partner Level+ Leader] </b>in your organization. Please consider
          carefully before proceeding.
        </Label>
      </div>

      <Field
        required
        label={<span className={styles.blockTitle}>ADO link</span>}
        validationState={toLabelStatus(
          jobPriorityStore.ADOLinkValidationStatus,
        )}
        validationMessage={toLabelMessage(
          jobPriorityStore.ADOLinkValidationStatus,
        )}
      >
        <Input
          size="medium"
          placeholder="Paste ADO link here"
          value={jobPriorityStore.jobPriorityADOLink}
          onChange={(_, data) => {
            updateJobPriorityADOLinkAction(data.value);
          }}
        />
      </Field>
      <Field
        label={<span className={styles.blockTitle}>Description</span>}
        validationState="none"
      >
        <Textarea
          appearance="outline"
          placeholder="Input more description about business impact"
          aria-label="app configuration editor."
          value={jobPriorityStore.jobPriorityDescription}
          onChange={(_, data) => {
            updateJobPriorityDescriptionAction(data.value);
          }}
        />
      </Field>
    </>
  );
});

import {
  Field,
  InfoLabel,
  Input,
  shorthands,
  tokens,
} from "@fluentui/react-components";
import { observer } from "mobx-react-lite";
import type { BizChatExp } from "sydneyeval-shared";
import { ResponsiveRow } from "../../../../../components/Responsive/ResponsiveRow";
import { makeResponsiveStyles } from "../../../../../components/Responsive/makeResponsiveStyles";
import { isFeatureEnabled } from "../../../../../selectors/features";
import { updatePropValueActionV2 } from "../../../actions/jobActions";
import { getJobErrorByPath } from "../../../selectors/creationError/getJobCreationError";
import { getValue } from "../../../selectors/getJobPropV2";
import { ExpPairExperimentView } from "./ExpPairExperimentView";
import { JobGPTAgentView } from "./JobGPTAgentView";
const useStyles = makeResponsiveStyles(
  {
    root: {
      backgroundColor: "white",
      boxSizing: "border-box",
      display: "flex",
      flexDirection: "column",
      ...shorthands.gap("20px"),
      ...shorthands.padding("24px", "87px", "24px", "87px"),
      ...shorthands.border("1px", "solid", "#EDEBE9"),
      ...shorthands.borderRadius("8px"),
    },
    columnWithSmallerGap: {
      display: "flex",
      width: "100%",
      flexDirection: "column",
      ...shorthands.gap("8px"),
    },
    block: {
      display: "flex",
      flexDirection: "column",
      ...shorthands.flex(1),
      width: "100%",
      ...shorthands.gap("8px"),
    },
    blockTitle: {
      fontFamily: tokens.fontFamilyBase,
      fontSize: "16px",
      fontWeight: 600,
      lineHeight: "22px",
    },
    experimentationFieldContainer: {
      display: "flex",
      flexDirection: "row",
      ...shorthands.gap("8px"),
      justifyContent: "space-between",
      alignItems: "center",
      width: "100%",
    },
    experimentationFieldTitle: {
      fontFamily: tokens.fontFamilyBase,
      fontSize: "14px",
      fontWeight: 400,
      lineHeight: "20px",
      width: "110px",
    },
  },
  {
    xs: {
      root: {
        ...shorthands.padding("24px", "24px", "24px", "24px"),
      },
    },
  },
);

export const JobPairExperimentationView = observer(() => {
  const styles = useStyles();
  const experiments = getValue("configs_in_json.conversations.exp_configs");

  const renderBlockContent = (isControl: boolean, config: BizChatExp) => {
    const nameError = isControl
      ? getJobErrorByPath(
          "configs_in_json.conversations.exp_configs[0].exp_name",
        )
      : getJobErrorByPath(
          "configs_in_json.conversations.exp_configs[1].exp_name",
        );

    const urlError = isControl
      ? getJobErrorByPath(
          "configs_in_json.conversations.exp_configs[0].sydney.url",
        )
      : getJobErrorByPath(
          "configs_in_json.conversations.exp_configs[1].sydney.url",
        );
    return (
      <div className={styles.columnWithSmallerGap}>
        <div className={styles.experimentationFieldContainer}>
          <InfoLabel
            required
            className={styles.experimentationFieldTitle}
            info={
              "The name of flight. Default value for control flight is control and treatment is experiment."
            }
          >
            Exp_name:
          </InfoLabel>
          <Field
            style={{ flexGrow: 1 }}
            validationState={
              nameError?.message === undefined ? "none" : "error"
            }
            validationMessage={nameError?.message}
          >
            <Input
              aria-label="The name of flight. Default value for control flight is control and treatment is experiment."
              size="medium"
              value={config.exp_name}
              onChange={(_, data) => {
                const path = isControl
                  ? ("configs_in_json.conversations.exp_configs[0].exp_name" as const)
                  : ("configs_in_json.conversations.exp_configs[1].exp_name" as const);

                updatePropValueActionV2({
                  prop: path,
                  newData: data.value,
                });
              }}
            />
          </Field>
        </div>
        <div className={styles.experimentationFieldContainer}>
          <InfoLabel
            required
            className={styles.experimentationFieldTitle}
            info={"The url used for scraping."}
          >
            URL:
          </InfoLabel>
          <Field
            style={{ flexGrow: 1 }}
            validationState={urlError === undefined ? "none" : "error"}
            validationMessage={urlError?.message}
          >
            <Input
              aria-label="The url used for scraping."
              size="medium"
              value={config?.sydney?.url}
              onChange={(_, data) => {
                const path = isControl
                  ? ("configs_in_json.conversations.exp_configs[0].sydney.url" as const)
                  : ("configs_in_json.conversations.exp_configs[1].sydney.url" as const);

                updatePropValueActionV2({
                  prop: path,
                  newData: data.value,
                });
              }}
            />
          </Field>
        </div>
        <div className={styles.experimentationFieldContainer}>
          <span className={styles.experimentationFieldTitle}>Option_sets:</span>
          <Input
            aria-label="Option sets for the flight."
            style={{ flexGrow: 1 }}
            size="medium"
            value={config?.sydney?.option_sets}
            onChange={(_, data) => {
              const path = isControl
                ? ("configs_in_json.conversations.exp_configs[0].sydney.option_sets" as const)
                : ("configs_in_json.conversations.exp_configs[1].sydney.option_sets" as const);

              updatePropValueActionV2({
                prop: path,
                newData: data.value,
              });
            }}
          />
        </div>
        <ExpPairExperimentView
          isControl={isControl}
          config={config}
          path={
            isControl
              ? "configs_in_json.conversations.exp_configs[0].sydney.variants"
              : "configs_in_json.conversations.exp_configs[1].sydney.variants"
          }
        />
        {isFeatureEnabled("gpt-agent-list") && (
          <JobGPTAgentView scope={isControl ? "control" : "treatment"} />
        )}
      </div>
    );
  };

  if (experiments === undefined || experiments.length !== 2) {
    return <></>;
  }

  return (
    <ResponsiveRow
      maxColumnCount={2}
      maxColumnCountSmall={1}
      columnGap={32}
      rowGap={20}
    >
      <div className={styles.block}>
        <div className={styles.blockTitle}>Control</div>
        {renderBlockContent(true, experiments[0])}
      </div>
      {experiments.length > 1 && (
        <div className={styles.block}>
          <div className={styles.blockTitle}>Treatment</div>
          {renderBlockContent(false, experiments[1])}
        </div>
      )}
    </ResponsiveRow>
  );
});

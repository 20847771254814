import {
  Field,
  InfoLabel,
  Textarea,
  makeStyles,
  tokens,
} from "@fluentui/react-components";
import { computed } from "mobx";
import { observer } from "mobx-react-lite";
import React from "react";
import { updatePropValueActionV2 } from "../../../actions/jobActions";
import { updateInputCache } from "../../../mutators/jobMutators";
import { getJobErrorByPath } from "../../../selectors/creationError/getJobCreationError";
import { jobStore } from "../../../store/jobStore";

const useStyles = makeStyles({
  experimentationFieldTitle: {
    fontFamily: tokens.fontFamilyBase,
    fontSize: "14px",
    fontWeight: 400,
    lineHeight: "20px",
    width: "110px",
  },
  fieldStyle: { flexGrow: 1 },
  textBoxHeight: {
    height: "100px",
  },
});

type Props = {
  fieldLabel?: string;
  fieldInfor: string;
  initValue: string;
  path:
    | `exp_configs[${number}].sydney.options`
    | `configs_in_json.conversations.exp_configs[${number}].sydney.plugins`
    | `exp_configs[${number}].extra_fields`;
  isRequired?: boolean;
};

export const JobEnginesJsonFieldView = observer((props: Props) => {
  const styles = useStyles();

  const cacheValue = computed(() => jobStore.inputCache.get(props.path)).get();
  React.useEffect(() => {
    if (props.initValue === undefined) {
      updateInputCache(props.path, "");
      return;
    }
    const currentCache = jobStore.inputCache.get(props.path);
    if (currentCache === props.initValue) {
      return;
    }

    updateInputCache(props.path, props.initValue);
  }, [props.initValue, props.path]);

  const updatePropValueIfNeeded = (inputCache: string) => {
    if (inputCache === undefined || inputCache === "") {
      updatePropValueActionV2({
        prop: props.path,
        newData: undefined,
      });
      return;
    }

    try {
      const parsedInputCache: unknown = JSON.parse(inputCache);
      if (typeof parsedInputCache === "object" && parsedInputCache !== null) {
        updatePropValueActionV2({
          prop: props.path,
          newData: parsedInputCache,
        });
      }
    } catch (e) {
      return;
    }
  };

  const error = getJobErrorByPath(props.path);

  return (
    <>
      {props.fieldLabel && (
        <InfoLabel
          className={styles.experimentationFieldTitle}
          info={props.fieldInfor}
        >
          {props.fieldLabel}:
        </InfoLabel>
      )}
      <Field
        className={styles.fieldStyle}
        validationState={error === undefined ? "none" : "error"}
        validationMessage={error?.message}
      >
        <Textarea
          aria-label={props.fieldInfor}
          className={styles.textBoxHeight}
          size="medium"
          value={cacheValue ?? ""}
          defaultValue={props.initValue}
          onChange={(_, data) => {
            updateInputCache(props.path, data.value);
            updatePropValueIfNeeded(data.value);
          }}
        />
      </Field>
    </>
  );
});

import { Link as FluentLink, makeStyles } from "@fluentui/react-components";
import { observer } from "mobx-react-lite";
import * as React from "react";
import { Link } from "react-router-dom";
import { Exp_Workspace_Id } from "../../../../constants/constants";
import type { Scorecard } from "../../models/Experiment";
import { formatDatetime } from "../../utils/format";
import { PaginationTable } from "../common/PaginationTable";
import { StatusTag } from "../common/StatusTag";

const useStyles = makeStyles({
  emptyTable: {
    width: "100%",
    height: "100%",
    backgroundColor: "#FAFAFA",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
});

interface IExperimentTableProps {
  allScorecards: Scorecard[];
  filter: {
    experiment?: string;
    status?: string;
    progression?: string;
    ring?: string;
    activeProgression?: boolean;
  };
}
export const ExperimentScorecardTable = observer(
  (props: IExperimentTableProps) => {
    const { filter, allScorecards } = props;
    const styles = useStyles();
    const [totalNum, setTotalNum] = React.useState(allScorecards.length);
    const [skipNum, setSkipNum] = React.useState(0);
    const [pageSize, setPageSize] = React.useState(10);
    const [scorecards, setScorecards] = React.useState<Scorecard[]>([]);

    React.useEffect(() => {
      const filterScorecards = allScorecards.filter((scorecard) => {
        if (
          filter.status !== "All" &&
          scorecard.scorecardState !== filter.status
        ) {
          return false;
        }
        if (
          filter.progression !== "All" &&
          scorecard.progressionName !== filter.progression
        ) {
          return false;
        }
        if (
          filter.ring !== "All" &&
          `${scorecard.stageName} ${scorecard.experimentStepName}` !==
            filter.ring
        ) {
          return false;
        }
        if (
          filter.activeProgression &&
          scorecard.isProgressionActive === false
        ) {
          return false;
        }
        return true;
      });
      setTotalNum(filterScorecards.length);
      const showScorecards = filterScorecards.slice(
        skipNum,
        skipNum + pageSize,
      );
      setScorecards(showScorecards);
    }, [skipNum, pageSize, filter, allScorecards]);

    const listProps = React.useMemo(() => {
      const header = [
        {
          key: "scorecardId",
          title: "Scorecard ID",
          minWidth: 110,
          width: 110,
          render: (item: Scorecard) => {
            return (
              <Link
                to={`/shadowabscorecard/${item.scorecardId}?experimentId=${item.experimentId}`}
              >
                <FluentLink>{item.scorecardId}</FluentLink>
              </Link>
            );
          },
        },
        // {
        //   key: "timeRangeStart",
        //   title: "Time range",
        //   minWidth: 104,
        //   width: 120,
        //   render: (item: Scorecard) => {
        //     const timeRangeDay =
        //       item.timeRangeEnd && item.timeRangeStart
        //         ? Math.floor(
        //             (new Date(item.timeRangeEnd).getTime() -
        //               new Date(item.timeRangeStart).getTime()) /
        //               1000 /
        //               3600 /
        //               24,
        //           )
        //         : NaN;
        //     return <>{isNaN(timeRangeDay) ? "" : `${timeRangeDay}day(s)`}</>;
        //   },
        // },
        {
          key: "scorecardState",
          title: "Status",
          minWidth: 104,
          width: 120,
          render: (item: Scorecard) => (
            <StatusTag label={item.scorecardState ?? ""} />
          ),
        },
        // {
        //   key: "analysisStudyName",
        //   title: "Analysis name",
        //   minWidth: 220,
        //   width: 260,
        //   render: (item: Scorecard) => (
        //     <Link
        //       to={`https://exp.microsoft.com/a/feature/id/analysis-results/${item.experimentStepId}/scorecards/study/${item.analysisStudyId}?workspaceId=${Exp_Workspace_Id}`}
        //       target="_blank"
        //     >
        //       <FluentLink>{item.analysisStudyName}</FluentLink>
        //     </Link>
        //   ),
        // },
        {
          key: "experimentStepName",
          title: "Stage and step",
          minWidth: 120,
          width: 160,
          render: (item: Scorecard) => (
            <>
              {item.stageName} {item.experimentStepName}
            </>
          ),
        },
        {
          key: "progressionName",
          title: "Progression",
          minWidth: 220,
          width: 260,
          render: (item: Scorecard) => (
            <Link
              to={`https://exp.microsoft.com/a/feature/${item.experimentId}?workspaceId=${Exp_Workspace_Id}`}
              target="_blank"
            >
              <FluentLink>{item.progressionName}</FluentLink>
            </Link>
          ),
        },
        {
          key: "createdTime",
          title: "Created at",
          minWidth: 186,
          width: 200,
          render: (item: Scorecard) => (
            <>{formatDatetime(item.createdTime ?? "")}</>
          ),
        },
      ];
      return {
        header,
        data: scorecards,
        keyName: "scorecardId",
        options: {
          innerBorder: true,
          renderEmpty: () => (
            <div className={styles.emptyTable}>
              Scorecards will be displayed here once they are available.
            </div>
          ),
        },
        pagination: {
          totalNum,
          skipNum,
          pageSize,
        },
        onPageChange: (page: number) => {
          setSkipNum((page - 1) * pageSize);
        },
        onPageSizeChange: (result: number) => {
          setPageSize(result);
        },
      };
    }, [totalNum, skipNum, pageSize, scorecards]);

    return <PaginationTable {...listProps} />;
  },
);

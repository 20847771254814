import jschardet from "jschardet";
import { folderPath_CWCQuerySet } from "../../../constants/constants";
import { getFileName, getFilePrefix } from "../../../helpers/accountHelper";
import {
  checkLocalQuerySetContent,
  getCustomQuerySets,
} from "../../../helpers/apiHelper";
import type { QuerySetFile } from "../../../models/QuerySetFile";
import { store } from "../../../store/store";
import type { QuerySetData } from "../models/QuerySets";
import { jobStore } from "../store/jobStore";

export const readFileAsText = (file: File): Promise<string> => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();

    reader.onload = (event) => {
      const contents = String(event.target?.result);
      resolve(contents);
    };

    reader.onerror = (event) => {
      reject(event.target?.error);
    };

    reader.readAsText(file);
  });
};

const convertFileToString = (file: File) => {
  return readFileAsText(file);
};

const readFileAsBinaryString = (file: File): Promise<string> => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();

    reader.onload = (event) => {
      const contents = String(event.target?.result);
      resolve(contents);
    };

    reader.onerror = (event) => {
      reject(event.target?.error);
    };

    reader.readAsBinaryString(file);
  });
};

const checkFileExistence = (file: File) => {
  const fileName = getFileName(store.account, file) ?? "";

  return getCustomQuerySets().then((customQuerySets) => {
    if (customQuerySets.map((_) => _.name).includes(fileName)) {
      throw new Error(`The file ${fileName} already exists.`);
    }

    return Promise.resolve(file);
  });
};

export const checkFileEncoding = (file: File) => {
  return readFileAsBinaryString(file).then((_) => {
    const result = jschardet.detect(_);
    if (result.encoding !== "UTF-8" && result.encoding !== "ascii") {
      throw new Error(
        `The file encoding is ${result.encoding}, please re-save the file with UTF-8 encoding.`,
      );
    }

    return file;
  });
};

const checkFileName = (file: File) => {
  const regex = /[&]/g;
  if (regex.test(file.name)) {
    // validation failed
    return Promise.reject(new Error("Invalid characters '&' in file name!"));
  }
  return Promise.resolve(file);
};

export const checkQuerySet = (file: File) => {
  return checkFileName(file)
    .then(checkFileExistence)
    .then(checkFileEncoding)
    .then(convertFileToString)
    .then(() =>
      checkLocalQuerySetContent(
        file,
        jobStore.selectedTemplate?.ExperimentName,
      ),
    );
};

export const getCreatorName = (filename: string) => {
  if (
    store.account?.localAccountId !== undefined &&
    filename.startsWith(store.account.localAccountId)
  ) {
    return store.account?.username ?? "Yourself";
  }

  if (filename.indexOf(folderPath_CWCQuerySet) >= 0) {
    return store.account?.username ?? "Yourself";
  }

  if (!filename.includes("-")) {
    return "";
  }

  const splittedString = filename.split("-");

  if (splittedString.length === 0) {
    return "";
  }

  if (
    (splittedString[0] === "v" || splittedString[0] === "t") &&
    splittedString.length > 2
  ) {
    return splittedString[0] + "-" + splittedString[1];
  }

  return splittedString[0];
};

const sortMergedQuerySets = (querySetsData: QuerySetData[]) => {
  querySetsData.sort((a, b) => {
    const aTime = a.file.lastModified
      ? new Date(a.file.lastModified).getTime()
      : a.file.createdOn
      ? new Date(a.file.createdOn).getTime()
      : new Date(0).getTime();

    const bTime = b.file.lastModified
      ? new Date(b.file.lastModified).getTime()
      : b.file.createdOn
      ? new Date(b.file.createdOn).getTime()
      : new Date(0).getTime();

    return bTime - aTime;
  });
};

export const mergeMChatQuerySets = (
  publicQuerySets: QuerySetFile[] = [],
  customQuerySets: QuerySetFile[] = [],
  generatedQuerySets: QuerySetFile[] = [],
): QuerySetData[] => {
  const publicQuerySetsDataSource: QuerySetData[] = publicQuerySets.map(
    (_) => ({ source: "public", file: _ }),
  );

  // Merge duplicate bizchat query sets by name
  const querySetGenerationsSet = new Set(
    generatedQuerySets.map((it) => it.name),
  );
  const customQuerySetsDataSource: QuerySetData[] = customQuerySets
    .filter(
      (_) =>
        getCreatorName(_.name) === getFilePrefix(store.account) &&
        !querySetGenerationsSet.has(_.name),
    )
    .map((_) => ({ source: "bizchat", file: _ }));

  const generatedQuerySetsDataSource: QuerySetData[] = generatedQuerySets.map(
    (_) => ({
      source: "bizchatGeneration",
      file: _,
    }),
  );

  const finalQuerySets = customQuerySetsDataSource
    .concat(publicQuerySetsDataSource)
    .concat(generatedQuerySetsDataSource);

  sortMergedQuerySets(finalQuerySets);
  return finalQuerySets;
};

export const mergeBingQuerySets = (
  customQuerySets: QuerySetFile[] = [],
  generatedQuerySets: QuerySetFile[] = [],
): QuerySetData[] => {
  const customQuerySetsDataSource: QuerySetData[] = customQuerySets.map(
    (_) => ({
      source: "bing",
      file: _,
    }),
  );

  const generatedQuerySetsDataSource: QuerySetData[] = generatedQuerySets.map(
    (_) => ({
      source: "bingGeneration",
      file: _,
    }),
  );

  const finalQuerySets = customQuerySetsDataSource.concat(
    generatedQuerySetsDataSource,
  );

  sortMergedQuerySets(finalQuerySets);
  return finalQuerySets;
};

import {
  Body1,
  Button,
  Switch,
  makeStyles,
  mergeClasses,
  shorthands,
} from "@fluentui/react-components";
import {
  ArrowLeft24Filled,
  ArrowLeft24Regular,
  ChevronDown12Filled,
  ChevronDown12Regular,
  ChevronUp12Filled,
  ChevronUp12Regular,
  ScalesRegular,
  StarRegular,
  WarningRegular,
  bundleIcon,
} from "@fluentui/react-icons";
import { useEffect, useMemo, useState } from "react";
import type {
  LMChecklistAssertion,
  LMChecklistAssertionsResponse,
} from "../../../models/LMChecklistAssertionsResponse";

import { observer } from "mobx-react-lite";
import ReactMarkdown from "react-markdown";
import { Tip } from "../../../../../components/Shared/Tip";
import { closeSidePane } from "../../../../../mutators/sidePaneMutators";
import type { LMChecklistSydneyReply } from "../../../models/LMChecklistDetailsResponse";
import type { LMChecklistIcmsResponse } from "../../../models/LMChecklistIcmsResponse";
import { IcmButton } from "../../Other/IcmButton";
import { SingleAssertionTable } from "../Table/SingleAssertionTable";

const useStyles = makeStyles({
  root: {
    display: "flex",
    flexDirection: "column",
  },
  titleRow: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },
  backButton: {
    width: "24px",
    height: "24px",
    cursor: "pointer",
  },
  title: {
    fontSize: "20px",
    fontStyle: "normal",
    fontWeight: "600",
    lineHeight: "20px",
    ...shorthands.margin("0", "0", "0", "9px"),
  },
  detailsSection: {
    marginTop: "37px",
    display: "flex",
    flexDirection: "column",
  },
  sectionTitle: {
    color: "#1B1A19",
    fontSize: "15px",
    fontStyle: "normal",
    fontWeight: "600",
    lineHeight: "20px",
  },
  sectionContent: {
    color: "#000",
    fontSize: "15px",
    fontStyle: "normal",
    fontWeight: "400",
    lineHeight: "20px",
  },
  details: {
    marginTop: "12px",
    ...shorthands.borderRadius("6px"),
    ...shorthands.border("1px", "solid", "#EDEBE9"),
    ...shorthands.overflow("hidden"),
    backgroundColor: "#FFF",
    "& .fui-TreeItemLayout__main": {
      boxSizing: "border-box",
      maxWidth: "100%",
      lineHeight: "20px",
      fontSize: "14px",
      fontStyle: "normal",
      fontWeight: "400",
      color: "#11100F",
    },
    cursor: "pointer",
  },
  detailsTitle: {
    height: "32px",
    backgroundColor: "#EFF6FC",
    ...shorthands.padding("0", "16px"),
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    ...shorthands.gap("8px"),
  },
  detailsTitleContent: {
    fontSize: "14px",
    fontStyle: "normal",
    fontWeight: "600",
    lineHeight: "32px",
    width: "0",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    ...shorthands.overflow("hidden"),
    ...shorthands.flex(1),
  },
  sydneyReply: {
    ...shorthands.padding("16px"),
    ...shorthands.overflow("auto"),
    wordWrap: "break-word",
  },
  sectionTitleRow: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  criteria: {
    display: "flex",
    flexDirection: "column",
  },
  criteriaTabs: {
    marginTop: "10px",
  },
  divider: {
    marginTop: "10px",
  },
  button: {
    marginRight: "5px",
  },
  active: {
    backgroundColor: "black",
    color: "white",
    "&:hover": {
      backgroundColor: "black",
      color: "white",
    },
  },
  expandedContent: {
    ...shorthands.padding("8px", "16px"),
  },
});

const BackIcon = bundleIcon(ArrowLeft24Filled, ArrowLeft24Regular);
const ArrowUpIcon = bundleIcon(ChevronUp12Filled, ChevronUp12Regular);
const ArrowDownIcon = bundleIcon(ChevronDown12Filled, ChevronDown12Regular);

interface AssertionDetailsProps {
  JobId: string;
  query: string;
  queryHash: string;
  assertions: LMChecklistAssertionsResponse;
  sydneyReply: LMChecklistSydneyReply;
  icms: LMChecklistIcmsResponse | undefined;
}

type LMChecklistAssertionWithIcm = LMChecklistAssertion & {
  icm?: number | undefined;
};
type LMChecklistAssertionWithIcmArray = ReturnType<
  typeof Array<LMChecklistAssertionWithIcm>
>;

export type AssertionLevel = "critical" | "expected" | "aspirational";

export const AssertionDetails = observer((props: AssertionDetailsProps) => {
  const styles = useStyles();

  const activeButton = mergeClasses(styles.button, styles.active);

  const [isExpanded, setIsExpanded] = useState<Map<string, boolean>>(new Map());
  const [allExpanded, setAllExpanded] = useState<boolean>(true);
  const [selectedLevel, setAssertionLevel] =
    useState<AssertionLevel>("critical");
  const [isMarkdown, setIsMarkdown] = useState<boolean>(true);

  const assertionsWithIcm = useMemo(() => {
    const icmMap = new Map<string, number>();
    if (props.icms !== undefined) {
      props.icms.forEach((icm) => {
        icmMap.set(`${icm.QueryHash}|${icm.Assertion}`, icm.IncidentId);
      });
    }

    return props.assertions.map((assertion) => {
      const icm = icmMap.get(`${assertion.queryHash}|${assertion.assertion}`);
      return { ...assertion, icm: icm };
    });
  }, [props.assertions]);

  const [filteredAssertions, setFilteredAssertions] =
    useState<LMChecklistAssertionWithIcmArray>(assertionsWithIcm);

  useEffect(() => {
    const filtered = assertionsWithIcm.filter(
      (assertion) => assertion.level === selectedLevel,
    );
    setFilteredAssertions(filtered);
    setAllExpanded(true);
  }, [assertionsWithIcm, props.icms, selectedLevel]);

  useEffect(() => {
    const filtered = assertionsWithIcm.filter(
      (assertion) => assertion.level === selectedLevel,
    );
    setFilteredAssertions(filtered);
    setAllExpanded(true);
  }, [assertionsWithIcm, selectedLevel]);

  useEffect(() => {
    const newIsExpanded = new Map(isExpanded);
    filteredAssertions.forEach((assertion, index) => {
      newIsExpanded.set(
        `${assertion.queryHash}-${assertion.level}-${index}`,
        allExpanded,
      );
    });
    setIsExpanded(newIsExpanded);
  }, [filteredAssertions, allExpanded]);

  const checkManualExpandedStates = (newIsExpanded: Map<string, boolean>) => {
    const expandedStates = filteredAssertions.map((assertion, index) =>
      newIsExpanded.get(`${assertion.queryHash}-${assertion.level}-${index}`),
    );

    const allExpanded = !expandedStates.includes(false);
    const allCollapsed = !expandedStates.includes(true);

    if (allExpanded) {
      setAllExpanded(true);
    } else if (allCollapsed) {
      setAllExpanded(false);
    }
  };

  const handleLevelSelect = (level: AssertionLevel) => {
    setAssertionLevel(level);
  };

  const getAssertionBackgroundColor = (assertion: LMChecklistAssertion) => {
    const { experiment: exp_score, control: con_score } = assertion.score;

    if (
      selectedLevel === "critical" &&
      exp_score !== undefined &&
      exp_score < 2
    ) {
      return "rgba(221, 66, 66, 0.30)";
    }

    if (exp_score === undefined || con_score === undefined) {
      return undefined;
    }

    if (exp_score < con_score) {
      return "rgba(254, 247, 178, 0.3)";
    }
  };

  const renderSydneyReply = (reply: string | undefined, treatment: string) => {
    if (reply === undefined) {
      reply = "No response returned.";
    }

    return (
      <div className={styles.details}>
        <div
          className={styles.detailsTitle}
          onClick={() => {
            const isExpandedKey = `${props.queryHash}-${treatment}`;
            const newIsExpanded = new Map(isExpanded);
            newIsExpanded.set(isExpandedKey, !isExpanded.get(isExpandedKey));
            setIsExpanded(newIsExpanded);
          }}
        >
          <div>
            {isExpanded.get(`${props.queryHash}-${treatment}`) ? (
              <ArrowUpIcon />
            ) : (
              <ArrowDownIcon />
            )}
          </div>
          <Body1 className={styles.detailsTitleContent}>{treatment}</Body1>
        </div>

        {isExpanded.get(`${props.queryHash}-${treatment}`) &&
          (isMarkdown ? (
            <ReactMarkdown className={styles.sydneyReply}>
              {reply}
            </ReactMarkdown>
          ) : (
            <div className={styles.sydneyReply}>{reply}</div>
          ))}
      </div>
    );
  };

  const renderAssertions = () => {
    return filteredAssertions.length === 0 ? (
      <p>{`No ${selectedLevel} assertions.`}</p>
    ) : (
      filteredAssertions.map((assertion, index) => (
        <div key={index} className={styles.details}>
          <div
            className={styles.detailsTitle}
            style={{
              backgroundColor: getAssertionBackgroundColor(assertion),
            }}
            onClick={() => {
              const isExpandedKey = `${assertion.queryHash}-${assertion.level}-${index}`;
              const newIsExpanded = new Map(isExpanded);
              newIsExpanded.set(isExpandedKey, !isExpanded.get(isExpandedKey));
              setIsExpanded(newIsExpanded);
              checkManualExpandedStates(newIsExpanded);
            }}
          >
            <div>
              {isExpanded.get(
                `${assertion.queryHash}-${assertion.level}-${index}`,
              ) ? (
                <ArrowUpIcon />
              ) : (
                <ArrowDownIcon />
              )}
            </div>
            <Tip withArrow content={assertion.assertion} relationship="label">
              <Body1 className={styles.detailsTitleContent}>
                {assertion.assertion}
              </Body1>
            </Tip>
            {assertion.icm && <IcmButton icm={assertion.icm} />}
          </div>

          {isExpanded.get(
            `${assertion.queryHash}-${assertion.level}-${index}`,
          ) && <SingleAssertionTable assertion={assertion} />}
        </div>
      ))
    );
  };

  return (
    <div className={styles.root}>
      <div className={styles.titleRow}>
        <div
          className={styles.backButton}
          role="button"
          aria-label="Back Button"
          onClick={() => {
            closeSidePane();
          }}
        >
          <BackIcon />
        </div>
        <Body1 className={styles.title}>Assertion details</Body1>
      </div>
      <div className={styles.detailsSection}>
        <Body1 className={styles.sectionTitle}>Utterance:</Body1>
        <Body1 className={styles.sectionContent}>{props.query}</Body1>
      </div>

      <div className={styles.detailsSection}>
        <div className={styles.sectionTitleRow}>
          <Body1 className={styles.sectionTitle}>Sydney Reply:</Body1>
          <Switch
            checked={isMarkdown}
            onChange={() => setIsMarkdown(!isMarkdown)}
            label="Markdown"
          />
        </div>
        <Body1 className={styles.sectionContent}>
          {renderSydneyReply(props.sydneyReply.control, "Control")}
        </Body1>
        <Body1 className={styles.sectionContent}>
          {renderSydneyReply(props.sydneyReply.experiment, "Treatment")}
        </Body1>
      </div>
      <div className={styles.detailsSection}>
        <div className={styles.sectionTitleRow}>
          <Body1 className={styles.sectionTitle}>Assertions:</Body1>
          <Button onClick={() => setAllExpanded(!allExpanded)}>
            {allExpanded ? "Collapse" : "Expand"} all
          </Button>
        </div>
      </div>
      <div className={styles.criteria}>
        <div className={styles.criteriaTabs}>
          <Button
            className={
              selectedLevel === "critical" ? activeButton : styles.button
            }
            icon={<WarningRegular />}
            shape="circular"
            onClick={() => handleLevelSelect("critical")}
          >
            Critical
          </Button>
          <Button
            className={
              selectedLevel === "expected" ? activeButton : styles.button
            }
            icon={<ScalesRegular />}
            shape="circular"
            onClick={() => handleLevelSelect("expected")}
          >
            Expected
          </Button>
          <Button
            className={
              selectedLevel === "aspirational" ? activeButton : styles.button
            }
            icon={<StarRegular />}
            shape="circular"
            onClick={() => handleLevelSelect("aspirational")}
          >
            Aspirational
          </Button>
        </div>
      </div>
      {renderAssertions()}
    </div>
  );
});

import { Button, makeStyles } from "@fluentui/react-components";

import { observer } from "mobx-react-lite";
import { IcmIcon } from "../../../../components/Shared/Icons";

const useStyles = makeStyles({
  icmButton: {
    backgroundColor: "rgb(0, 120, 212)",
    ":hover": {
      backgroundColor: "rgb(0, 120, 212)",
      filter: "brightness(0.8)",
    },
    ":focus": {
      backgroundColor: "rgb(0, 120, 212)",
      filter: "brightness(0.8)",
    },
    ":active": {
      backgroundColor: "rgb(0, 120, 212)",
      filter: "brightness(0.8)",
    },
    ":hover:active": {
      backgroundColor: "rgb(0, 120, 212)",
      filter: "brightness(0.8)",
    },
  },
});

export interface IcmButtonProps {
  icm: number;
}

export const IcmButton = observer((props: IcmButtonProps) => {
  const styles = useStyles();

  const { icm } = props;

  return (
    <Button
      aria-label={"Link for Assertion IcM"}
      className={styles.icmButton}
      icon={<IcmIcon />}
      onClick={(event) => {
        event?.stopPropagation();
        window.open(
          `https://portal.microsofticm.com/imp/v3/incidents/details/${icm}/home`,
          "_blank",
        );
      }}
    />
  );
});

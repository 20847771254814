import {
  Button,
  Portal,
  shorthands,
  Spinner,
  tokens,
} from "@fluentui/react-components";
import React, { useEffect } from "react";
import {
  createJob,
  getBingProductMetaData,
  getCustomLMChecklistSets,
  getCustomQuerySets,
  getJobTemplates,
  getPublicLMChecklistSets,
  listProducts,
  verifyToken,
} from "../../../../helpers/apiHelper";
import {
  updateJsonStringAction,
  updatePropValueActionV2,
  updateSelectedTemplateAction,
} from "../../actions/jobActions";
import type { JobTemplate } from "../../models/JobTemplate";
import {
  resetJobCreationStore,
  updateIsPreValidating,
  updateStatusIsLoading,
  updateStatusIsSubmitting,
  updateStatusIsTokenDialogOpen,
  updateStatusIsVerifying,
  updateTokenDialogEmail,
  updateTokenUploadingProps,
} from "../../mutators/jobCreationMutators";
import {
  resetJobStore,
  updateAvailableTemplates,
  updateSBSConfirmationResolve,
} from "../../mutators/jobMutators";
import {
  resetJobPreValidationSteps,
  updateJobPreValidationJobTemplateName,
  updateJobPreValidationResolve,
} from "../../mutators/jobPreValidationMutators";

import { computed } from "mobx";
import { observer } from "mobx-react-lite";
import { useLocation } from "react-router-dom";
import {
  BingMetaDataResponse,
  parseJsonStrOptional,
  TemplateType,
} from "sydneyeval-shared";
import { ResponsiveRow } from "../../../../components/Responsive/ResponsiveRow";
import { makeResponsiveStyles } from "../../../../components/Responsive/makeResponsiveStyles";
import { useToast } from "../../../../components/Wrappers/ToasterProvider";
import type { UserActionTelemetryEventName } from "../../../../constants/telemetryName";
import { getFilePrefix } from "../../../../helpers/accountHelper";
import { hasM365CopilotLicense } from "../../../../helpers/licenseHelper";
import {
  perfWrapper,
  telemetryHelper,
} from "../../../../helpers/telemetryHelper";
import { is3pExtPluginsConfigured } from "../../../../helpers/tokenHelper";
import { updateCurrentPath } from "../../../../mutators/updateContributions";
import { isFeatureEnabled } from "../../../../selectors/features";
import { store } from "../../../../store/store";
import { resetJobPriorityStateAction } from "../../actions/jobPriorityActions";
import {
  getNewVersionRules,
  getOPGRules,
  getSkipScrapingRules,
} from "../../helpers/editorRuleHelper";
import { getDefaultTemplate } from "../../helpers/jobCloneHelper";
import { getCreatorName } from "../../helpers/querySetHelper";
import {
  isPriorityJobADOLinkValid,
  updateADOLinkValidationStatus,
} from "../../helpers/validateADOLinkHelper";
import type { RefreshTokenType } from "../../models/Types";
import {
  updateProductJobTemplates,
  updateProductMetaData,
  updateProducts,
} from "../../mutators/updateProductSettings";
import { getJobCreationErrorMessage } from "../../selectors/creationError/getJobCreationError";
import { generateCurrentJob } from "../../selectors/generateCurrentJob";
import { getUserId, getValue } from "../../selectors/getJobPropV2";
import { getTemplateType } from "../../selectors/getTemplateType";
import { jobCreationStore } from "../../store/jobCreationStore";
import { jobPriorityStore } from "../../store/jobPriorityStore";
import { jobStore } from "../../store/jobStore";
import { TokenUploadingDialog } from "../Dialog/TokenUploadingDialog";
import { ShareButton } from "../JobDetail/components/ShareButton";
import { JSONEditorView } from "../Other/JSONEditorView";
import { JobConfigure3pPluginsBlockView } from "./components/Job3pPluginsConfigurationBlockView";
import { JobBingView } from "./components/JobBingView";
import { JobDataSetView } from "./components/JobDataSetView";
import { JobErrorToast } from "./components/JobErrorToast";
import { JobEvalTimeOverrideView } from "./components/JobEvalTimeOverrideView";
import { JobEvaluationTemplateView } from "./components/JobEvaluationTemplateView";
import { JobEvaluationTypeView } from "./components/JobEvaluationTypeView";
import { JobGPTIdentifierView } from "./components/JobGPTIdentifierView";
import { JobJsonConfigurationView } from "./components/JobJsonConfigurationView";
import { JobLLMChecklistDatasetView } from "./components/JobLLMChecklistDatasetView";
import { JobMetricsBlockView } from "./components/JobMetricsBlockView";
import { JobNameView } from "./components/JobNameView";
import { JobPairExperimentationView } from "./components/JobPairExperimentationView";
import { JobPreValidationToast } from "./components/JobPreValidationToast";
import { JobQuickFilterView } from "./components/JobQuickFilterView";
import { JobSBSConfirmationDialog } from "./components/JobSBSConfirmationDialog";
import { JobSbsExpContainer } from "./components/JobSBSExpContainer";
import { JobSelectedFileView } from "./components/JobSelectedFileView";
import { JobSetAsPriorityView } from "./components/JobSetAsPriorityView";
import { JobSimplifyPairExperimentationView } from "./components/JobSimplifyPairExperimentationView";
import { JobUploadFolderView } from "./components/JobUploadFolderView";
import { JobUserIdView } from "./components/JobUserIdView";

const preValidationRequiredTemplates = [
  "BizChat_Evaluation_Flight_Review",
  "BizChat_Evaluation_All_Metrics",
  "BizChat_Shared_Leo_Only",
  "BizChat_Sydney_Scraping_Only",
];
const useStyles = makeResponsiveStyles(
  {
    root: {
      backgroundColor: "white",
      boxSizing: "border-box",
      display: "flex",
      flexDirection: "column",
      ...shorthands.gap("20px"),
      ...shorthands.padding("24px", "87px", "24px", "87px"),
      ...shorthands.border("1px", "solid", "#EDEBE9"),
      ...shorthands.borderRadius("8px"),
    },
    rowWithMediumGap: {
      display: "flex",
      flexDirection: "row",
      width: "100%",
      ...shorthands.gap("32px"),
    },
    rowWithSmallGap: {
      display: "flex",
      width: "100%",
      flexDirection: "row",
      ...shorthands.gap("16px"),
    },
    columnWithSmallerGap: {
      display: "flex",
      width: "100%",
      flexDirection: "column",
      ...shorthands.gap("8px"),
    },
    block: {
      display: "flex",
      flexDirection: "column",
      ...shorthands.flex(1),
      width: "100%",
      ...shorthands.gap("8px"),
    },
    blockTitle: {
      fontFamily: tokens.fontFamilyBase,
      fontSize: "16px",
      fontWeight: 600,
      lineHeight: "22px",
    },
    dropdownMenu: {
      backgroundColor: "#F8F7F9",
    },
    spinner: {
      width: "100%",
    },
    metricsContainer: {
      ...shorthands.borderRadius("8px"),
      ...shorthands.border("1px", "solid", "#EDEBE9"),
      ...shorthands.padding("10px", "12px"),
    },
    checkboxContainer: {
      flexBasis: "1",
      flexGrow: "1",
      display: "flex",
      flexDirection: "row",
      ...shorthands.gap("12px"),
      fontFamily: tokens.fontFamilyBase,
      fontSize: "14px",
      fontWeight: 400,
      lineHeight: "20px",
    },
    checkbox: {
      '> input[type="checkbox"]:checked': {
        backgroundColor: "red",
        color: "red",
      },
    },
    jsonPreviewer: {
      height: "600px",
      "> span": {
        ...shorthands.flex(1),
      },
      "> textarea": {
        maxHeight: "inherit",
      },
    },
    dataSetsPreviewer: {
      height: "100px",
      "> span": {
        ...shorthands.flex(1),
      },
      "> textarea": {
        maxHeight: "inherit",
      },
    },
    divider: {
      width: "100%",
      height: "1px",
      backgroundColor: "#D2D0CE",
    },
    experimentationFieldContainer: {
      display: "flex",
      flexDirection: "row",
      ...shorthands.gap("8px"),
      justifyContent: "space-between",
      alignItems: "center",
      width: "100%",
    },
    experimentationFieldTitle: {
      fontFamily: tokens.fontFamilyBase,
      fontSize: "14px",
      fontWeight: 400,
      lineHeight: "20px",
      width: "110px",
    },
    headerContainer: {
      ...shorthands.margin("50px", "0", "28px", "0"),
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-between",
    },
    headerTitleContainer: {
      alignItems: "center",
      display: "flex",
      justifyContent: "center",
      width: "100%",
    },
    headerTitle: {
      fontFamily: tokens.fontFamilyBase,
      fontSize: "24px",
      lineHeight: "32px",
      fontWeight: 600,
    },
    listbox: {
      maxHeight: "300px",
    },
    textBoxHeight: {
      height: "100px",
    },
    warningBox: {
      color: tokens.colorStatusWarningBorderActive,
    },
    warningIcon: {
      verticalAlign: "middle",
    },
    operationButton: {
      display: "flex",
      ...shorthands.margin("50px", "0", "28px", "0"),
      flexDirection: "row-reverse",
      gap: "8px",
    },
    overlay: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      position: "fixed",
      top: 0,
      bottom: 0,
      left: 0,
      right: 0,
      background: "rgba(0, 0, 0, 0.4)",
    },
  },
  {
    xs: {
      root: {
        ...shorthands.padding("24px", "24px", "24px", "24px"),
      },
    },
  },
);

const refreshQuerySets = () => {
  return getCustomQuerySets().then((customQS) => {
    return customQS
      .filter((_) => getCreatorName(_.name) === getFilePrefix(store.account))
      .map((_) => _.name);
  });
};

export const JobCreationViewContent = observer(() => {
  const styles = useStyles();
  const toast = useToast();
  const state: unknown = useLocation().state;
  const isDisabled = computed(() => {
    return (
      jobCreationStore.isSubmitting ||
      jobCreationStore.isVerifying ||
      jobCreationStore.isPreValidating
    );
  });
  const needPreValidation = computed(() => {
    return preValidationRequiredTemplates.some(
      (templateName) =>
        jobStore.selectedTemplate?.Name.toLocaleLowerCase().startsWith(
          templateName.toLocaleLowerCase(),
        ),
    );
  });

  const verifyLicenseAndThrow = async () => {
    const userName = getUserId.get() ?? "";
    const evalType = jobStore.selectedEvaluationType;
    const jobTemplateType = getTemplateType.get();

    // Only check the license for personal account and biz chat flight review template
    // TODO: JINZHAN, add license check for other templates if needed.
    if (
      evalType === "PersonalAccount" &&
      jobTemplateType === TemplateType.BizChatFlightReview
    ) {
      if (!userName) {
        throw new Error("Failed to fetch the user account, please try again");
      }

      const hasValidLicense = await hasM365CopilotLicense(userName);
      if (!hasValidLicense) {
        throw new Error(
          "User does not have the service plan M365_COPILOT_BUSINESS_CHAT in Microsoft_365_Copilot license",
        );
      }
    }
  };

  const preValidationJob = () => {
    updateIsPreValidating(true);
    resetJobPreValidationSteps();
    updateJobPreValidationJobTemplateName(jobStore.selectedTemplate?.Name);
    new Promise<boolean>((resolve) => {
      updateJobPreValidationResolve(resolve);
    })
      .then((result) => {
        switch (result) {
          case true:
            toast.onToastSuccess("Job Creation Verified");
            submitJob();
            break;
          case false:
            toast.onToastFailure("Job Creation Verification Failed");
        }
      })
      .finally(() => {
        updateIsPreValidating(false);
      });
  };

  const submitJob = async () => {
    toast.onToastStart("Creating Job...");
    updateStatusIsSubmitting(true);

    telemetryHelper.logUserActionEvent("SubmitJob");

    try {
      if (isFeatureEnabled("m365chat-license-verifier")) {
        await verifyLicenseAndThrow();
      }

      const job = await generateCurrentJob();
      createJob(job)
        .then(
          (result) => {
            telemetryHelper.logUserActionEvent("SubmitJobSuccess", {
              data: result,
            });
            toast.onToastSuccess("Create Job Succeeded");
            updateCurrentPath("/");
          },
          (error) => {
            telemetryHelper.logUserActionEvent("SubmitJobFailure", {
              message: error.message,
            });
            toast.onToastFailure(`Create Job Failed: ${error.message}`);
          },
        )
        .finally(() => updateStatusIsSubmitting(false));
    } catch (error) {
      if (error instanceof Error) {
        telemetryHelper.logUserActionEvent("SubmitJobFailure", {
          message: error.message,
        });
        toast.onToastFailure(`Create Job Failed: ${error.message}`);
      }
    }
  };

  const onSubmitClicked = React.useCallback(() => {
    if (jobCreationStore.isSubmitting || jobCreationStore.isVerifying) {
      return;
    }

    toast.onToastStart("Verifying Job Creation...");
    updateStatusIsVerifying(true);

    if (jobStore.selectedTemplate === undefined) {
      toast.onToastFailure("Please select a template");
      updateStatusIsVerifying(false);
      return;
    }

    const errorMessage = getJobCreationErrorMessage.get();
    if (errorMessage !== undefined) {
      toast.onToastFailure(errorMessage);
      updateStatusIsVerifying(false);
      return;
    }

    const jobTemplateType = getTemplateType.get();
    const targetAccount = getUserId.get();

    const confirmSBSIfNeeded = () => {
      if (getValue("create_sbs_experiment") === true) {
        return new Promise<boolean>((resolve) => {
          updateSBSConfirmationResolve(resolve);
        }).then((result) => {
          switch (result) {
            case true:
              telemetryHelper.logUserActionEvent("ClickAcceptSBS");
              break;
            case false:
              telemetryHelper.logUserActionEvent("ClickDeclineSBS");
              break;
          }
          updatePropValueActionV2({
            prop: "create_sbs_experiment",
            newData: result,
          });
        });
      } else {
        return Promise.resolve();
      }
    };

    // If the job create_sbs_experiment is true, we should pop up the confirmation dialog.
    // After that We should verify the token when all the conditions are met:
    // 1. The template type is BizChatFlightReview or Normal, in this case the job is MChat job.
    // 2. The user has provided the target account
    confirmSBSIfNeeded()
      .then(async () => {
        if (
          isFeatureEnabled("job-priority") &&
          jobPriorityStore.isPriorityJob
        ) {
          await updateADOLinkValidationStatus();
          if (isPriorityJobADOLinkValid() === false) {
            throw new Error("The ADO link for priority job is invalid");
          }
        }
      })
      .then(async () => {
        const templateMatch =
          jobTemplateType === TemplateType.BizChatFlightReview ||
          jobTemplateType === TemplateType.Normal;

        const emailMatch = targetAccount !== undefined && targetAccount !== "";

        if (templateMatch && emailMatch) {
          // For MCP jobs, we only need to verify the 3S token.
          // For other jobs, for the feature "refresh token migration"
          // * when feature enabled, we only need to verify the SEVAL token
          // * when feature disabled, we need to verify the 3S token
          const isMCPJob =
            jobStore.selectedTemplate?.ExperimentName.startsWith("MCP_") ??
            false;
          const isMigrationEnabled = isFeatureEnabled(
            "refresh-token-migration",
          );

          // 3S token verification, always verify 3S token
          const substrateVerifyTokenResult = await verifyTokenAndLogTelemetry(
            "3S refresh token",
            "Token",
            targetAccount,
          );

          // SEVAL token verification, for MCP jobs, we don't need to verify the SEVAL token
          // then we always treat the SEVAL token as valid
          const sevalVerifyTokenResult = !isMCPJob
            ? await verifyTokenAndLogTelemetry(
                "SEVAL refresh token",
                "SevalRefreshToken",
                targetAccount,
              )
            : { isSuccess: true };

          // Teams cli token verification
          const teamsCliVerifyTokenResult = is3pExtPluginsConfigured()
            ? await verifyTokenAndLogTelemetry(
                "Teams CLI refresh token",
                "TeamsCliRefreshToken",
                targetAccount,
              )
            : {
                isSuccess: true,
              };

          // If teams cli token is invalid, we should update the token
          const shouldUpdateTeamsCliToken =
            !teamsCliVerifyTokenResult.isSuccess;

          // In those cases, we should ask user update substrate token
          // * Feature is not enabled and 3S token is invalid
          // * Job is MCP job and 3S token is invalid
          const shouldUpdateSubstrateToken =
            (isMCPJob && !substrateVerifyTokenResult.isSuccess) ||
            (!isMigrationEnabled && !substrateVerifyTokenResult.isSuccess);

          // In those cases, we should ask user update seval token (this step can skip)
          // * Feature is enabled and SEVAL token is invalid
          const shouldUpdateSevalToken =
            isMigrationEnabled && !sevalVerifyTokenResult.isSuccess;

          // If user only need to update the SEVAL token, and the 3S token is valid,
          // we can let user skip the token uploading to ensure a smooth experience
          const canSkipTokenUploading =
            !shouldUpdateTeamsCliToken &&
            !shouldUpdateSubstrateToken &&
            shouldUpdateSevalToken &&
            substrateVerifyTokenResult.isSuccess;

          if (
            shouldUpdateTeamsCliToken ||
            shouldUpdateSubstrateToken ||
            shouldUpdateSevalToken
          ) {
            updateTokenUploadingProps(
              shouldUpdateSubstrateToken,
              shouldUpdateSevalToken,
              shouldUpdateTeamsCliToken,
              canSkipTokenUploading,
            );

            updateTokenDialogEmail(targetAccount);
            updateStatusIsTokenDialogOpen(true);
            throw new Error(
              `Account Token Invalid: ${JSON.stringify({
                "3S refresh token": substrateVerifyTokenResult.errorMessage,
                "SEVAL refresh token": sevalVerifyTokenResult.errorMessage,
                "Teams CLI refresh token":
                  teamsCliVerifyTokenResult.errorMessage,
              })}`,
            );
          }
        } else {
          return Promise.resolve();
        }
      })
      .then(() => {
        //job pre-validation
        if (needPreValidation.get()) {
          preValidationJob();
        } else {
          // Token verification finished, submit the job
          toast.onToastSuccess("Job Creation Verified");
          submitJob();
        }
      })
      .catch((error) => {
        toast.onToastFailure(error.toString());
      })
      .finally(() => {
        updateStatusIsVerifying(false);
      });
  }, []);

  const verifyTokenAndLogTelemetry = async (
    tokenType: RefreshTokenType,
    tokenShortName: string,
    targetAccount: string,
  ) => {
    let verifyTokenParsedResult: {
      isSuccess: boolean;
      errorMessage?: string;
    };
    telemetryHelper.logUserActionEvent(
      `Verify${tokenShortName}` as UserActionTelemetryEventName,
      {
        inputType: "Refreshtoken",
        source: "JobCreationViewContent",
      },
    );
    try {
      const verifyTokenResult = await verifyToken({
        Email: targetAccount,
        TokenType: tokenType,
      });
      if (!verifyTokenResult?.isValid) {
        verifyTokenParsedResult = {
          isSuccess: false,
          errorMessage: verifyTokenResult.message,
        };
        telemetryHelper.logUserActionEvent(
          `Verify${tokenShortName}Failure` as UserActionTelemetryEventName,
          {
            inputType: "Refreshtoken",
            message: verifyTokenResult.message,
            source: "JobCreationViewContent",
          },
        );
      } else {
        verifyTokenParsedResult = {
          isSuccess: true,
        };
        telemetryHelper.logUserActionEvent(
          `Verify${tokenShortName}Success` as UserActionTelemetryEventName,
          {
            inputType: "Refreshtoken",
            source: "JobCreationViewContent",
          },
        );
      }
    } catch (err) {
      verifyTokenParsedResult = {
        isSuccess: false,
        errorMessage: err?.toString(),
      };
      telemetryHelper.logUserActionEvent(
        `Verify${tokenShortName}Failure` as UserActionTelemetryEventName,
        {
          inputType: "Refreshtoken",
          message: err?.toString(),
          source: "JobCreationViewContent",
        },
      );
    }

    return verifyTokenParsedResult;
  };

  const [customQuerySets, setCustomQuerySets] = React.useState<string[]>([]);
  const [customLMChecklistSets, setCustomLMChecklistSets] = React.useState<
    string[]
  >([]);
  const [publicLMChecklistSets, setPublicLMChecklistSets] = React.useState<
    string[]
  >([]);

  const handleJobTemplates = async (jobTemplates: JobTemplate[]) => {
    const templates = jobTemplates;

    updateAvailableTemplates(templates);

    const defaultTemplate = await getDefaultTemplate(state);
    if (defaultTemplate) {
      updateSelectedTemplateAction(defaultTemplate);
    }
  };

  useEffect(() => {
    updateStatusIsLoading(true);
    perfWrapper(
      "LoadCreateJobPage",
      Promise.all([
        getJobTemplates(),
        refreshQuerySets(),
        getCustomLMChecklistSets(),
        getPublicLMChecklistSets(),
        listProducts(),
        getBingProductMetaData("Bing").catch(() => undefined),
        getBingProductMetaData("CWC").catch(() => undefined),
      ]).then(
        ([
          jobTemplates,
          customQS,
          customLMChecklist,
          publicLMChecklist,
          products,
          bingMetaData,
          cwcMetaData,
        ]) => {
          updateProducts(products);
          updateProductJobTemplates(jobTemplates);
          updateProductMetaData(
            parseJsonStrOptional(bingMetaData, BingMetaDataResponse),
            parseJsonStrOptional(cwcMetaData, BingMetaDataResponse),
          );
          setCustomQuerySets(customQS);
          setCustomLMChecklistSets(customLMChecklist);
          setPublicLMChecklistSets(publicLMChecklist);
          handleJobTemplates(jobTemplates);
          updateStatusIsLoading(false);
        },
      ),
    );
    return () => {
      resetJobStore();
      resetJobCreationStore();
      resetJobPriorityStateAction();
    };
  }, []);

  const renderDivider = () => {
    return <div className={styles.divider} />;
  };

  const renderJSONTemplateContent = () => {
    return (
      <JSONEditorView
        key="general_json_editor"
        object={jobStore.configuration}
        path={""}
        rules={getNewVersionRules()}
        onChange={(configs: any) => {
          updateJsonStringAction(JSON.stringify(configs, null, 2));
        }}
      />
    );
  };

  const renderOPGTemplateContent = () => {
    return (
      <>
        <JobUploadFolderView />
        <JSONEditorView
          key="opg_json_editor"
          object={jobStore.configuration}
          path={""}
          rules={getOPGRules()}
          onChange={(configs: any) => {
            updateJsonStringAction(JSON.stringify(configs, null, 2));
          }}
        />
      </>
    );
  };

  const renderSkipScrapingTemplateContent = () => {
    return (
      <>
        <JobUploadFolderView />
        <JSONEditorView
          key="general_json_editor"
          object={jobStore.configuration}
          path={""}
          rules={getSkipScrapingRules()}
          onChange={(configs: unknown) => {
            updateJsonStringAction(JSON.stringify(configs, null, 2));
          }}
        />
        <JobLLMChecklistDatasetView
          publicLMChecklistSets={publicLMChecklistSets}
          customLMChecklistSets={customLMChecklistSets}
          setPublicLMChecklistSets={setPublicLMChecklistSets}
        />
      </>
    );
  };

  const refreshCustomQuerySets = () =>
    refreshQuerySets()
      .then(setCustomQuerySets)
      .catch(() => undefined);

  const renderNormalTemplateContent = () => {
    const selectedTemplate = jobStore.selectedTemplate;
    if (selectedTemplate === undefined) {
      return <></>;
    }
    return (
      <>
        <JobMetricsBlockView />
        <JobSelectedFileView
          customQuerySets={customQuerySets}
          setCustomQuerySets={setCustomQuerySets}
          refreshCustomQuerySets={refreshCustomQuerySets}
        />
        <JobLLMChecklistDatasetView
          publicLMChecklistSets={publicLMChecklistSets}
          customLMChecklistSets={customLMChecklistSets}
          setPublicLMChecklistSets={setPublicLMChecklistSets}
        />
        <JobUserIdView />
        {isFeatureEnabled("eval-time-override") && <JobEvalTimeOverrideView />}
        {renderDivider()}
        <JobPairExperimentationView />
        {!isFeatureEnabled("gpt-agent-list") && <JobGPTIdentifierView />}
        <JobSbsExpContainer />
        <JobConfigure3pPluginsBlockView />
        {renderDivider()}
      </>
    );
  };

  const renderBizChatFlightReviewTemplateContent = () => {
    const selectedTemplate = jobStore.selectedTemplate;
    if (selectedTemplate === undefined) {
      return <></>;
    }
    return (
      <>
        <JobSelectedFileView
          customQuerySets={customQuerySets}
          setCustomQuerySets={setCustomQuerySets}
          refreshCustomQuerySets={refreshCustomQuerySets}
        />
        <JobUserIdView />
        <JobSimplifyPairExperimentationView />
        {!isFeatureEnabled("gpt-agent-list") && <JobGPTIdentifierView />}
        <JobSbsExpContainer />
        <JobConfigure3pPluginsBlockView />
      </>
    );
  };

  const renderShareSubmitButton = () => {
    return (
      <div className={styles.operationButton}>
        <Button
          disabled={isDisabled.get()}
          appearance={"primary"}
          onClick={onSubmitClicked}
        >
          Submit
        </Button>
        <ShareButton />
      </div>
    );
  };

  const renderHeader = () => {
    return (
      <div>
        <div>
          <div className={styles.headerTitleContainer}>
            <span className={styles.headerTitle}>Create a new evaluation</span>
          </div>
        </div>
        <div>{!jobCreationStore.isLoading && renderShareSubmitButton()}</div>
      </div>
    );
  };

  const renderTemplateByType = () => {
    switch (getTemplateType.get()) {
      case TemplateType.JSON:
        return renderJSONTemplateContent();
      case TemplateType.Normal:
        return renderNormalTemplateContent();
      case TemplateType.BingV2:
      case TemplateType.CWC:
        return <JobBingView />;
      case TemplateType.OPG:
        return renderOPGTemplateContent();
      case TemplateType.GeneralSkipScraping:
        return renderSkipScrapingTemplateContent();
      case TemplateType.BizChatFlightReview:
        return renderBizChatFlightReviewTemplateContent();
    }
  };

  const renderContentIfNeeded = () => {
    if (jobCreationStore.isLoading) {
      return (
        <div className={styles.headerContainer}>
          <Spinner
            className={styles.spinner}
            labelPosition="below"
            label="Loading Job Templates..."
          />
        </div>
      );
    }

    return (
      <div className={styles.root}>
        {isFeatureEnabled("job-priority") && <JobSetAsPriorityView />}
        <JobQuickFilterView />
        <JobNameView />
        <ResponsiveRow
          maxColumnCount={2}
          maxColumnCountSmall={1}
          rowGap={20}
          columnGap={20}
        >
          <JobEvaluationTemplateView />
          {getTemplateType.get() === TemplateType.Normal &&
            jobStore.selectedTemplate !== undefined && (
              <JobEvaluationTypeView />
            )}
        </ResponsiveRow>
        <JobDataSetView />
        {renderTemplateByType()}
        <JobJsonConfigurationView />
        <JobErrorToast />
        <JobPreValidationToast />
        <Portal>
          {isDisabled.get() && <div className={styles.overlay} />}
        </Portal>
      </div>
    );
  };

  const renderTokenDialog = () => {
    return (
      <TokenUploadingDialog
        isOpen={jobCreationStore.isTokenDialogOpen}
        email={jobCreationStore.tokenDialogEmail}
        onSuccess={() => {
          updateStatusIsTokenDialogOpen(false);
          if (needPreValidation.get()) {
            preValidationJob();
          } else {
            submitJob();
          }
        }}
        onCancel={updateStatusIsTokenDialogOpen.bind(null, false)}
        shouldUploadSevalToken={jobCreationStore.sevalTokenVerifyFailed}
        shouldUploadSssToken={jobCreationStore.substrateTokenVerifyFailed}
        shouldUploadTeamsCliToken={jobCreationStore.teamsCliTokenVerifyFailed}
        canSkipTokenUploading={jobCreationStore.canSkipTokenUploading}
      />
    );
  };

  return (
    <>
      {renderHeader()}
      {renderContentIfNeeded()}
      {renderTokenDialog()}
      <JobSBSConfirmationDialog />
    </>
  );
});

import {
  DeprecatedSydneyClientSecret,
  GeneralSydneyClientSecret,
} from "../../../../../constants";

export const checkSydneyClientSecret = (
  value: string | undefined,
  path: string,
) => {
  if (value === undefined) {
    return undefined;
  }

  const validValues = [DeprecatedSydneyClientSecret, GeneralSydneyClientSecret];

  return validValues.includes(value)
    ? undefined
    : {
        message: `Sydney client secret is invalid, the expected value is ${validValues}`,
        path,
      };
};

import {
  Body1Strong,
  Button,
  Combobox,
  Dialog,
  DialogActions,
  DialogBody,
  DialogContent,
  DialogSurface,
  DialogTitle,
  Field,
  Link as FluentLink,
  Label,
  Option,
  Radio,
  RadioGroup,
  Text,
  makeStyles,
} from "@fluentui/react-components";
import { Lightbulb16Regular, Open20Regular } from "@fluentui/react-icons";
import { observer } from "mobx-react-lite";
import * as React from "react";
import { getNexusUrlByJobSettings } from "../../components/Shared/helpers/ShadowABExperimentHelper";
import { Exp_Workspace_Id } from "../../constants/constants";
import { getExperiments } from "../../helpers/apiHelper";
import { perfWrapper } from "../../helpers/telemetryHelper";
import type { Job } from "../../partners/OfflineEval/models/Job";
import type { Experiment } from "../../partners/ShadowAB/models/Experiment";
import { getUserAlias } from "../../partners/ShadowAB/utils/account";

const useStyle = makeStyles({
  title: {
    width: "100%",
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
  dialogContent: {
    margin: "16px 0 ",
  },
  fieldLabel: {
    fontWeight: 500,
    margin: "8px 0",
  },
  tips: {
    color: "#616161",
    fontSize: "12px",
    margin: "8px 0 16px",
  },
  nexusContainer: {
    marginTop: "24px",
    backgroundColor: "#EBF3FC",
    border: "1px solid #B4D6FA",
    borderRadius: "8px",
    padding: "12px",
  },
  nexusTitle: {
    display: "flex",
    alignItems: "center",
    color: "#242424",
  },
  nexusContent: {
    margin: "8px 4px",
    color: "#242424",
  },
  longButton: {
    whiteSpace: "nowrap",
  },
});

export const ShadowABExperimentSetupDialog = observer(
  (props: { isOpen: boolean; onDismiss: () => void; upstreamJob?: Job }) => {
    const myAlias = getUserAlias();
    const { isOpen, onDismiss, upstreamJob } = props;
    const styles = useStyle();
    const [experiments, setExperiments] = React.useState<Experiment[]>([]);
    const [experimentId, setExperimentId] = React.useState("");
    const [experimentOperation, setExperimentOperation] =
      React.useState("create");
    React.useEffect(() => {
      const params = {
        workspaceId: Exp_Workspace_Id,
        query: {
          owner: myAlias,
        },
      };
      perfWrapper(
        "LoadExperimentList",
        getExperiments(params).then((exps) => {
          // setExperiments(exps.filter((exp) => exp.owners?.includes(myAlias)));
          setExperiments(exps);
        }),
      );
    }, [myAlias]);
    const handleSetupExperiment = () => {
      if (experimentOperation === "create") {
        window.open(
          `https://exp.microsoft.com/a/CreateExp/SelectTemplate?workspaceId=${Exp_Workspace_Id}`,
          "_blank",
        );
      } else {
        window.open(
          `https://exp.microsoft.com/a/feature/${experimentId}?workspaceId=${Exp_Workspace_Id}`,
          "_blank",
        );
      }
    };
    const handleNexus = () => {
      if (!upstreamJob) {
        return;
      }
      const url = getNexusUrlByJobSettings(upstreamJob);
      window.open(url, "_blank");
    };

    return (
      <Dialog
        modalType="modal"
        open={isOpen}
        onOpenChange={(_, data) => {
          if (data.open === true) {
            onDismiss?.();
          }
        }}
      >
        <DialogSurface>
          <DialogBody>
            <DialogTitle className={styles.title} data-testid="dialog-title">
              {"New shadow AB"}
              {upstreamJob ? ` from ${upstreamJob.JobName}` : ""}
            </DialogTitle>
            <DialogContent className={styles.dialogContent}>
              <Field>
                <Label className={styles.fieldLabel}>Select experiment</Label>
                <RadioGroup
                  layout="horizontal"
                  onChange={(_, data) => setExperimentOperation(data.value)}
                >
                  <Radio
                    value="create"
                    label="Create new experiment"
                    defaultChecked={true}
                  />
                  <Radio
                    data-testid="select-existing"
                    value="exist"
                    label="Select existing experiment"
                  />
                </RadioGroup>
              </Field>
              {experimentOperation === "create" && (
                <Field className={styles.tips}>
                  <Text>
                    You'll be guided to the{" "}
                    <FluentLink
                      href={`https://exp.microsoft.com/?workspaceId=${Exp_Workspace_Id}`}
                      target="_blank"
                    >
                      Control Tower
                    </FluentLink>{" "}
                    to complete the experiment setup.
                  </Text>
                  <div className={styles.nexusContainer}>
                    <Body1Strong className={styles.nexusTitle} block={true}>
                      <Lightbulb16Regular />
                      Important
                    </Body1Strong>
                    <Text className={styles.nexusContent} block={true}>
                      Set up your experiment on Control Tower by using the
                      config from Nexus Hub as your treatment variable value.
                      Simply go to Nexus Hub, grab the config, and paste it in
                      Control Tower.
                    </Text>
                    <Button
                      size="small"
                      data-testid="nexus-link"
                      className={styles.longButton}
                      onClick={handleNexus}
                      icon={<Open20Regular />}
                    >
                      Generate config on Nexus
                    </Button>
                  </div>
                </Field>
              )}
              {experimentOperation === "exist" && (
                <>
                  <Field className={styles.tips}>
                    <Text>
                      The shadow AB will be based on an existing experiment and
                      a cloned progression. You'll be guided to the{" "}
                      <FluentLink
                        href={`https://exp.microsoft.com/?workspaceId=${Exp_Workspace_Id}`}
                        target="_blank"
                      >
                        Control Tower
                      </FluentLink>{" "}
                      to continue.
                    </Text>
                  </Field>
                  <Field label="Experiment" required={true}>
                    <Combobox
                      onOptionSelect={(_e, data) =>
                        setExperimentId(data.optionValue ?? "")
                      }
                    >
                      {experiments.map((exp) => (
                        <Option key={exp.experimentId} value={exp.experimentId}>
                          {exp.experimentName ?? ""}
                        </Option>
                      ))}
                    </Combobox>
                  </Field>
                </>
              )}
            </DialogContent>

            <DialogActions>
              <Button
                data-testid="exp-link"
                className={styles.longButton}
                onClick={handleSetupExperiment}
                appearance="primary"
                icon={<Open20Regular />}
              >
                {`Go to Control Tower and ${
                  experimentOperation === "create" ? "start" : "continue"
                }`}
              </Button>
              <Button onClick={() => onDismiss()} appearance="secondary">
                Cancel
              </Button>
            </DialogActions>
          </DialogBody>
        </DialogSurface>
      </Dialog>
    );
  },
);

import { makeStyles } from "@fluentui/react-components";
import { observer } from "mobx-react-lite";
import * as React from "react";
import type { Experiment } from "../../models/Experiment";
import { ShadowABBreadcrumb } from "../ShadowABBreadcrumb";
import { ExperimentPanel } from "./ExperimentPanel";
import { ExperimentScorecardList } from "./ExperimentScorecardList";
const useStyles = makeStyles({
  root: {
    display: "flex",
    justifyContent: "flex-start",
    minHeight: "100%",
    height: "100%",
  },
  leftPanel: {
    display: "flex",
    flex: "0 0 216px",
    flexDirection: "column",
    width: "216px",
    backgroundColor: "transparent",
    minHeight: "100%",
  },
  listPanel: {
    display: "flex",
    flexDirection: "column",
    flex: 1,
    backgroundColor: "#FFF",
    minHeight: "100%",
    height: "100%",
    boxShadow: "0px 2px 4px 0px #0000001A",
  },
});

export const ExperimentContainer = observer(() => {
  const styles = useStyles();
  const [selectedExperiment, setSelectedExperiment] = React.useState<
    Experiment | undefined
  >();
  const [showPanel, setShowPanel] = React.useState<boolean>(true);
  return (
    <>
      <ShadowABBreadcrumb nav={[{ text: "Experiments" }]} />
      <div className={styles.root}>
        <div
          className={styles.leftPanel}
          style={{ display: showPanel ? "flex" : "none" }}
        >
          <ExperimentPanel
            selectedExperiment={selectedExperiment}
            setSelectedExperiment={setSelectedExperiment}
            showPanel={showPanel}
            setShowPanel={setShowPanel}
          />
        </div>
        <div className={styles.listPanel}>
          <ExperimentScorecardList
            selectedExperiment={selectedExperiment}
            setSelectedExperiment={setSelectedExperiment}
            showPanel={showPanel}
            setShowPanel={setShowPanel}
          />
        </div>
      </div>
    </>
  );
});

import {
  Body1,
  Button,
  Dialog,
  DialogActions,
  DialogBody,
  DialogContent,
  DialogSurface,
  DialogTitle,
  Field,
  makeStyles,
  typographyStyles,
} from "@fluentui/react-components";
import { computed } from "mobx";
import { observer } from "mobx-react-lite";
import React, { useEffect, useRef } from "react";
import { folderPath_CWCQuerySet } from "../../../../constants/constants";
import { getFileName } from "../../../../helpers/accountHelper";
import { validateQuerySetAgent } from "../../../../helpers/apiHelper";
import { getProductFileName } from "../../../../helpers/appEnvHelper";
import { isFeatureEnabled } from "../../../../selectors/features";
import { store } from "../../../../store/store";
import { uploadFile } from "../../actions/uploadFile";
import { checkAIGraphFile } from "../../helpers/AIGraphHelper";
import { checkCWCTestSet } from "../../helpers/CWCSetHelper";
import { checkLMChecklistSet } from "../../helpers/lmchecklistSetHelper";
import { checkProductBackupFile } from "../../helpers/productHelper";
import { checkQuerySet } from "../../helpers/querySetHelper";
import { MockDeclarativeAgentsHint } from "../JobCreation/components/JobGPTAgentView";

const useStyles = makeStyles({
  warning: typographyStyles.caption1Strong,
});
interface IUploadingFileDialogProps {
  type:
    | "Query"
    | "LM Checklist"
    | "Bing"
    | "AI-Graph"
    | "CWC"
    | "ProductsBackup";
  subFolder?: string;
  isOpen: boolean;
  onStart?: (fileName: string) => void;
  onSuccess?: (fileName: string) => void;
  onFailure?: (error: Error) => void;
  onCancel?: () => void;
}

export const UploadingFileDialog = observer(
  (props: IUploadingFileDialogProps) => {
    const fileInputRef = useRef<HTMLInputElement>(null);
    const { type, isOpen } = props;
    const style = useStyles();

    const computedRootFolderName = computed(() => {
      switch (type) {
        case "Query":
          return "queryset";
        case "LM Checklist":
          return "queryset/lmchecklist";
        case "CWC":
          return folderPath_CWCQuerySet;
        case "AI-Graph":
          return "ai-graph";
        case "ProductsBackup":
          return "settings";
      }
    });

    const computedFolderName = computed(() => {
      if (props.subFolder === undefined) {
        return computedRootFolderName.get();
      }
      return `${computedRootFolderName.get()}/${props.subFolder}`;
    });

    const computedFileName = computed(() => {
      if (selectedFile === undefined) {
        return undefined;
      }

      const folderName = computedFolderName.get();

      switch (type) {
        case "CWC":
          return `${folderName}/${selectedFile.name}`;
        case "ProductsBackup":
          return `settings/${getProductFileName()}`;
        default:
          return `${folderName}/${getFileName(store.account, selectedFile)}`;
      }
    });

    const computedFileExtension = computed(() => {
      switch (type) {
        case "Query":
          return ".tsv";
        case "LM Checklist":
          return ".yaml,.yml";
        case "CWC":
          return ".tsv";
        case "AI-Graph":
          return ".json";
        case "ProductsBackup":
          return ".json";
      }
    });

    const computedDisplayFileName = computed(() => {
      const fileName = computedFileName.get();
      if (fileName?.startsWith("queryset/")) {
        return fileName.substring(9);
      }

      if (fileName?.startsWith("ai-graph/")) {
        return fileName.substring(9);
      }

      return fileName;
    });

    const [selectedFile, setSelectedFile] = React.useState<File | undefined>(
      undefined,
    );

    const [validationErrorMessage, setValidationErrorMessage] = React.useState<
      string | undefined
    >(undefined);

    const [validationWarningMessage, setValidationWarningMessage] =
      React.useState<string | undefined>(undefined);

    useEffect(() => {
      setSelectedFile(undefined);
      setValidationWarningMessage(undefined);
    }, [isOpen]);

    const handleFileDialogConfirm = () => {
      const currentFileName = computedFileName.get();
      const displayFileName = computedDisplayFileName.get();

      if (
        displayFileName !== undefined &&
        currentFileName !== undefined &&
        selectedFile !== undefined
      ) {
        props.onStart?.(currentFileName);

        uploadFile(
          currentFileName,
          selectedFile,
          type === "CWC" ? "Bing" : "MChat", // Here means using Bing blob for now.
          () => {
            props.onSuccess?.(displayFileName);
          },
          props.onFailure,
        );
      } else {
        setValidationErrorMessage("Please select a file to upload.");
      }
    };

    const validationState = computed(() => {
      if (selectedFile === undefined) {
        return "none";
      }
      if (validationErrorMessage === undefined) {
        return "warning";
      }
      if (validationErrorMessage === "") {
        return "success";
      }
      return "error";
    });

    const validationMessage = computed(() => {
      if (selectedFile === undefined) {
        return "";
      }
      if (validationErrorMessage === undefined) {
        return `Checking ${type} Set...`;
      }
      if (validationErrorMessage === "") {
        return `${type} Set validation passed.`;
      }
      return validationErrorMessage;
    });

    const isConfirmButtonDisabled = computed(() => {
      return (
        selectedFile === undefined ||
        computedFileName.get() === undefined ||
        validationState.get() !== "success"
      );
    });

    const currentFileName = computedDisplayFileName.get();

    return (
      <Dialog
        open={isOpen}
        onOpenChange={(_, data) => {
          if (data.open === false) {
            props.onCancel?.();
          }
        }}
      >
        <DialogSurface>
          <DialogBody>
            <DialogTitle>{`Upload ${type} Set File`}</DialogTitle>
            <DialogContent>
              <Field
                validationState={validationState.get()}
                validationMessage={validationMessage.get()}
              >
                <div>
                  <Body1>{`You can upload your ${type.toLowerCase()} set here.\n`}</Body1>
                  {currentFileName && (
                    <Body1>{`This file will be stored with name `}</Body1>
                  )}
                  {currentFileName && <strong>{`${currentFileName}`}</strong>}
                </div>
              </Field>
              <input
                ref={fileInputRef}
                type="file"
                accept={computedFileExtension.get()}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                  const file = event.target?.files?.[0];
                  if (file) {
                    setSelectedFile(file);
                    setValidationErrorMessage(undefined);
                    setValidationWarningMessage(undefined);

                    Promise.resolve()
                      .then(() => {
                        switch (type) {
                          case "Query":
                            if (isFeatureEnabled("gpt-agent-list")) {
                              validateQuerySetAgent(file).then((result) => {
                                if (result !== undefined) {
                                  setValidationWarningMessage(result);
                                } else {
                                  setValidationWarningMessage(undefined);
                                }
                              });
                            }
                            return checkQuerySet(file).then(() =>
                              Promise.resolve(),
                            );
                          case "LM Checklist":
                            return checkLMChecklistSet(file).then(() =>
                              Promise.resolve(),
                            );
                          case "CWC":
                            return checkCWCTestSet(file).then(() =>
                              Promise.resolve(),
                            );
                          case "AI-Graph":
                            return checkAIGraphFile(file).then(() =>
                              Promise.resolve(),
                            );
                          case "ProductsBackup":
                            return checkProductBackupFile(file).then(() =>
                              Promise.resolve(),
                            );
                        }
                      })
                      .then(() => {
                        setValidationErrorMessage("");
                      })
                      .catch((error) => {
                        setValidationErrorMessage(error.message);
                      })
                      .finally(() => {
                        if (fileInputRef.current) {
                          fileInputRef.current.value = "";
                        }
                      });
                  }
                }}
                style={{ display: "none" }}
              />
              <br />
              <br />
              {validationWarningMessage && (
                <div className={style.warning}>
                  {validationWarningMessage}
                  <br />
                  {MockDeclarativeAgentsHint}
                </div>
              )}
              <Button
                appearance="primary"
                onClick={() => {
                  fileInputRef.current?.click();
                }}
              >
                Select File
              </Button>
            </DialogContent>

            <DialogActions>
              <Button
                onClick={handleFileDialogConfirm}
                disabled={isConfirmButtonDisabled.get()}
                appearance="primary"
              >
                Confirm
              </Button>
              <Button onClick={props.onCancel} appearance="secondary">
                Close
              </Button>
            </DialogActions>
          </DialogBody>
        </DialogSurface>
      </Dialog>
    );
  },
);

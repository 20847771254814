import { validateADOLink } from "../../../helpers/apiHelper";
import { updateJobPriorityADOLinkValidationStatusAction } from "../actions/jobPriorityActions";
import { jobPriorityStore } from "../store/jobPriorityStore";

export const isPriorityJobADOLinkValid = () => {
  // This is intented to treat "Warning" as true to allow the user to proceed when ADO link validation failed
  return (
    jobPriorityStore.ADOLinkValidationStatus === "valid" ||
    jobPriorityStore.ADOLinkValidationStatus === "warning"
  );
};

export const updateADOLinkValidationStatus = async () => {
  const ADOLink = jobPriorityStore.jobPriorityADOLink;

  if (ADOLink === undefined || ADOLink === "") {
    updateJobPriorityADOLinkValidationStatusAction("empty");
    return;
  }
  const { status } = await validateADOLink({
    ADOLink: ADOLink,
  });
  try {
    switch (status) {
      case "valid":
        updateJobPriorityADOLinkValidationStatusAction("valid");
        break;
      case "invalid":
        updateJobPriorityADOLinkValidationStatusAction("invalid");
        break;
      case "unknown":
        updateJobPriorityADOLinkValidationStatusAction("warning");
        break;
      default:
        updateJobPriorityADOLinkValidationStatusAction("pending");
    }
  } catch (error) {
    updateJobPriorityADOLinkValidationStatusAction("warning");
  }
};

import {
  Button,
  Dropdown,
  Option,
  shorthands,
  Subtitle1,
  Switch,
  tokens,
} from "@fluentui/react-components";
import { Open20Regular, PanelLeftExpand20Regular } from "@fluentui/react-icons";
import { observer } from "mobx-react-lite";
import * as React from "react";
import { ResponsiveRow } from "../../../../components/Responsive/ResponsiveRow";
import { makeResponsiveStyles } from "../../../../components/Responsive/makeResponsiveStyles";
import { Exp_Workspace_Id } from "../../../../constants/constants";
import { getExperimentScorecards } from "../../../../helpers/apiHelper";
import { perfWrapper } from "../../../../helpers/telemetryHelper";
import type { Scorecard } from "../../models/Experiment";
import type { IExperimentList } from "./ExperimentInterface";
import { ExperimentScorecardTable } from "./ExperimentScorecardTable";

const useStyles = makeResponsiveStyles(
  {
    root: {
      display: "flex",
      flexDirection: "column",
      alignItems: "flex-start",
      boxSizing: "border-box",
      minHeight: "100%",
      height: "100%",
      maxHeight: "calc(100vh - 100px)",
      padding: "24px",
      overflow: "auto",
    },
    title: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-between",
      width: "100%",
    },
    titleLeft: {
      display: "flex",
      alignItems: "center",
    },
    titleRight: {
      display: "flex",
      alignItems: "center",
    },
    columnContainer: {
      display: "flex",
      flexDirection: "column",
    },
    rowContainer: {
      display: "flex",
      flexDirection: "row",
      alignItems: "flex-start",
      ...shorthands.gap("8px"),
    },
    listTable: {
      width: "100%",
      flex: 1,
    },
    label: {
      ...shorthands.margin("5px", "0", "5px", "0"),
      fontFamily: tokens.fontFamilyBase,
      fontWeight: 600,
      fontSize: "12px",
      lineHeight: "16px",
      fontStyle: "normal",
      color: "#605E5C",
    },
    functionBarContainer: {
      width: "100%",
      ...shorthands.margin("15px", "0", "20px", "0"),
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      flexWrap: "wrap-reverse",
      justifyContent: "space-between",
    },
    icon: {
      marginRight: "18px",
    },
  },
  {
    xs: {
      rowContainer: {
        width: "100%",
      },
    },
    sm: {
      rowContainer: {
        width: "100%",
      },
    },
    md: {
      rowContainer: {
        width: "100%",
      },
    },
    lg: {
      rowContainer: {
        maxWidth: "70%",
      },
    },
  },
);

export const ExperimentScorecardList = observer((props: IExperimentList) => {
  const styles = useStyles();
  const { selectedExperiment, showPanel, setShowPanel } = props;
  const [allScorecards, setAllScorecards] = React.useState<Scorecard[]>([]);
  const [scorecardFilterStatus, setScorecardFilterStatus] =
    React.useState("All");
  const [scorecardFilterProgression, setScorecardFilterProgression] =
    React.useState("All");
  const [scorecardFilterRing, setScorecardFilterRing] = React.useState("All");
  const [
    scorecardFilterActiveProgression,
    setScorecardFilterActiveProgression,
  ] = React.useState(false);

  React.useEffect(() => {
    if (!selectedExperiment?.experimentId) {
      return;
    }
    const params = {
      workspaceId: Exp_Workspace_Id,
      query: {
        experimentId: selectedExperiment?.experimentId,
      },
    };
    perfWrapper(
      "LoadExperimentScorecardList",
      getExperimentScorecards(params).then((scorecards) => {
        setAllScorecards(
          scorecards.sort(
            (a, b) => Number(b.scorecardId) - Number(a.scorecardId),
          ),
        );
      }),
    );
  }, [selectedExperiment]);

  const filterDropdownOptions = React.useMemo(() => {
    const statusSet = new Set<string>(["All"]);
    const progressionSet = new Set<string>(["All"]);
    const ringSet = new Set<string>(["All"]);
    allScorecards.forEach((scorecard) => {
      if (scorecard.scorecardState) {
        statusSet.add(scorecard.scorecardState);
      }
      if (scorecard.progressionName) {
        progressionSet.add(scorecard.progressionName);
      }
      if (scorecard.stageName && scorecard.experimentStepName) {
        ringSet.add(`${scorecard.stageName} ${scorecard.experimentStepName}`);
      }
    });
    return {
      status: Array.from(statusSet).map((v) => {
        return { key: v, value: v, name: v };
      }),
      progression: Array.from(progressionSet).map((v) => {
        return { key: v, value: v, name: v };
      }),
      ring: Array.from(ringSet).map((v) => {
        return { key: v, value: v, name: v };
      }),
    };
  }, [allScorecards]);

  const scorecardFilter = React.useMemo(() => {
    return {
      experiment: selectedExperiment?.experimentId,
      status: scorecardFilterStatus,
      progression: scorecardFilterProgression,
      ring: scorecardFilterRing,
      activeProgression: scorecardFilterActiveProgression,
    };
  }, [
    selectedExperiment,
    scorecardFilterStatus,
    scorecardFilterProgression,
    scorecardFilterRing,
    scorecardFilterActiveProgression,
  ]);

  if (!selectedExperiment) {
    return (
      <div className={styles.root}>
        <div className={styles.title}>
          <div className={styles.titleLeft}>
            {showPanel ? (
              <></>
            ) : (
              <Button
                appearance="transparent"
                icon={<PanelLeftExpand20Regular />}
                className={styles.icon}
                onClick={() => {
                  setShowPanel(true);
                }}
              />
            )}
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className={styles.root}>
      <div className={styles.title}>
        <div className={styles.titleLeft}>
          {showPanel ? (
            <></>
          ) : (
            <Button
              appearance="transparent"
              icon={<PanelLeftExpand20Regular />}
              className={styles.icon}
              onClick={() => {
                setShowPanel(true);
              }}
            />
          )}
          <Subtitle1>{selectedExperiment?.experimentName ?? ""}</Subtitle1>
          <Button
            appearance="transparent"
            icon={<Open20Regular />}
            className={styles.icon}
          />
          <span>{selectedExperiment?.experimentCreateTime ?? ""}</span>
        </div>
        <div className={styles.titleRight}>
          <Switch
            checked={scorecardFilterActiveProgression}
            onChange={(_e, data) =>
              setScorecardFilterActiveProgression(data.checked)
            }
            label="Latest progression only"
          />
        </div>
      </div>
      <div className={styles.functionBarContainer}>
        <div className={styles.rowContainer}>
          <ResponsiveRow
            maxColumnCount={5}
            maxColumnCountSmall={1}
            columnGap={8}
          >
            <div className={styles.columnContainer}>
              <label className={styles.label}>Status</label>
              <Dropdown
                aria-label="Status Dropdown"
                value={scorecardFilterStatus}
                onOptionSelect={(_, data) => {
                  setScorecardFilterStatus(data.optionValue ?? "");
                }}
              >
                {filterDropdownOptions.status.map((option) => (
                  <Option key={option.key} value={option.value}>
                    {option.name}
                  </Option>
                ))}
              </Dropdown>
            </div>

            <div className={styles.columnContainer}>
              <label className={styles.label}>Progression name</label>
              <Dropdown
                aria-label="Progression Dropdown"
                value={scorecardFilterProgression}
                onOptionSelect={(_, data) => {
                  setScorecardFilterProgression(data.optionValue ?? "");
                }}
              >
                {filterDropdownOptions.progression.map((option) => (
                  <Option key={option.key} value={option.value}>
                    {option.name}
                  </Option>
                ))}
              </Dropdown>
            </div>

            <div className={styles.columnContainer}>
              <label className={styles.label}>Ring</label>
              <Dropdown
                aria-label="Ring Dropdown"
                value={scorecardFilterRing}
                onOptionSelect={(_, data) => {
                  setScorecardFilterRing(data.optionValue ?? "");
                }}
              >
                {filterDropdownOptions.ring.map((option) => (
                  <Option key={option.key} value={option.value}>
                    {option.name}
                  </Option>
                ))}
              </Dropdown>
            </div>
          </ResponsiveRow>
        </div>
      </div>
      <div className={styles.listTable}>
        <ExperimentScorecardTable
          filter={scorecardFilter}
          allScorecards={allScorecards}
        />
      </div>
    </div>
  );
});

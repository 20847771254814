import {
  Body1Strong,
  Button,
  Field,
  Link as FluentLink,
  makeStyles,
  TeachingPopover,
  TeachingPopoverBody,
  TeachingPopoverHeader,
  TeachingPopoverSurface,
  TeachingPopoverTitle,
  TeachingPopoverTrigger,
  Text,
  Title3,
  tokens,
} from "@fluentui/react-components";
import {
  ArrowLeft32Regular,
  Edit20Regular,
  Open20Regular,
  ReceiptSearch20Regular,
  Warning20Filled,
} from "@fluentui/react-icons";
import { observer } from "mobx-react-lite";
import React from "react";
import { Link, useLocation, useParams } from "react-router-dom";
import { Exp_Workspace_Id } from "../../../../constants/constants";
import {
  connectIteration,
  getExperimentScorecards,
  getUpstreamExperiment,
} from "../../../../helpers/apiHelper";
import { getRandomUUID } from "../../../../helpers/getRandomUUID";
import { perfWrapper } from "../../../../helpers/telemetryHelper";
import type { FeatureEvalExperiment, Scorecard } from "../../models/Experiment";
import { formatDatetime } from "../../utils/format";
import { StatusTag } from "../common/StatusTag";
import { ShadowABBreadcrumb } from "../ShadowABBreadcrumb";
import { ExperimentIterationDialog } from "./ExperimentIterationDialog";
import { ExperimentScorecardMetrics } from "./ExperimentScorecardMetrics";

const useStyles = makeStyles({
  root: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    minHeight: "100%",
    height: "100%",
  },
  title: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    width: "100%",
    marginTop: "36px",
    marginBottom: "16px",
  },
  meta: {
    display: "flex",
    justifyContent: "flex-start",
    backgroundColor: "#FFF",
    borderRadius: "8px",
    border: "1px solid #D1D1D1",
    padding: "24px",
  },
  metaColumn: {
    flex: 1,
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
  },
  metaItem: {
    marginBottom: "8px",
  },
  inlineButton: {
    display: "inline-block",
    color: tokens.colorNeutralForeground2BrandHover,
  },
  warningIcon: {
    marginRight: "4px",
    color: "#bc4b09",
    verticalAlign: "text-bottom",
  },
  tipsTitle: {
    fontSize: "16px",
    fontWeight: 600,
    marginBottom: "16px",
  },
});

interface IFlightReviewScorecardMeta {
  stage?: "Offline" | "Shadow" | "Online";
  id?: string;
  stepId?: string;
  displayName?: string;
  isTarget?: boolean;
}

export const ExperimentScorecardDetail = observer(() => {
  const styles = useStyles();
  const [connectDialogOpen, setConnectDialogOpen] = React.useState(false);
  const [offlineExperiment, setOfflineExperiment] = React.useState<
    FeatureEvalExperiment | undefined
  >();
  const [scorecardDetail, setScorecardDetail] = React.useState<
    Scorecard | undefined
  >();
  const experimentId = new URLSearchParams(useLocation().search).get(
    "experimentId",
  );
  const { scorecardId } = useParams();

  React.useEffect(() => {
    if (!experimentId) {
      return;
    }
    const params = {
      workspaceId: Exp_Workspace_Id,
      query: {
        experimentId,
      },
    };
    perfWrapper(
      "LoadExperimentScorecardList",
      getExperimentScorecards(params)
        .then((scorecards) => {
          setScorecardDetail(
            scorecards.find((s) => s.scorecardId === scorecardId),
          );
        })
        .catch(() => {
          setScorecardDetail(undefined);
        }),
    );
  }, [experimentId, scorecardId]);

  const getUpstreamOfflineExperiment = React.useCallback(() => {
    if (!scorecardDetail) {
      return;
    }
    getUpstreamExperiment({
      shadowABProgressionId: scorecardDetail.progressionId,
    })
      .then((data) => {
        if (data.ID) {
          setOfflineExperiment(data);
        }
      })
      .catch(() => {
        setOfflineExperiment(undefined);
      });
  }, [scorecardDetail]);

  React.useEffect(() => {
    getUpstreamOfflineExperiment();
  }, [getUpstreamOfflineExperiment]);

  const onConnectChange = React.useCallback(
    (offline?: FeatureEvalExperiment) => {
      if (offline && scorecardDetail) {
        connectIteration({
          iterationId: offlineExperiment?.IterationId ?? getRandomUUID(),
          featureId: offlineExperiment?.FeatureId ?? getRandomUUID(),
          offlineJobId: offline.ExternalId,
          offlineJobName: offline.Name,
          shadowABProgressionId: scorecardDetail.progressionId,
          shadowABProgressionName: scorecardDetail.progressionName,
          shadowABFeatureId: scorecardDetail.experimentId,
          shadowABFeatureName: scorecardDetail.experimentName,
        }).then(() => {
          getUpstreamOfflineExperiment();
        });
      }
      setConnectDialogOpen(false);
    },
    [offlineExperiment, scorecardDetail, getUpstreamOfflineExperiment],
  );

  const handleFlightReview = React.useCallback(() => {
    if (!scorecardDetail) {
      return;
    }
    const shadowABMeta: IFlightReviewScorecardMeta = {
      stage: "Shadow",
      id: scorecardDetail.scorecardId,
      stepId: scorecardDetail.experimentStepId,
      displayName: scorecardDetail.experimentName,
      isTarget: true,
    };
    const offlineMeta: IFlightReviewScorecardMeta | undefined =
      offlineExperiment
        ? {
            stage: "Offline",
            id: offlineExperiment?.ExternalId,
            displayName: offlineExperiment?.Name,
            isTarget: false,
          }
        : undefined;
    const query = new URLSearchParams({
      newFlightReview: JSON.stringify(
        offlineMeta ? [shadowABMeta, offlineMeta] : [shadowABMeta],
      ),
    });
    // Flight review url for test environment
    const flightReviewUrl = `https://3sflightreview-test.azurewebsites.net/FlightReviewList?${query.toString()}`;
    // Flight review url for production environment
    // const flightReviewUrl = `https://3sflightreview.azurewebsites.net/FlightReviewList?${query.toString()}`;
    window.open(flightReviewUrl, "_blank");
  }, [scorecardDetail, offlineExperiment]);

  return (
    <>
      <ShadowABBreadcrumb
        nav={
          scorecardDetail
            ? [
                { text: "Experiments", link: "/shadowab" },
                { text: scorecardDetail?.experimentName ?? "" },
                { text: `Scorecard: ${scorecardDetail?.scorecardId}` },
              ]
            : [{ text: "Experiments", link: "/shadowab" }]
        }
      />
      {scorecardDetail ? (
        <div className={styles.root}>
          <div className={styles.title}>
            <div className="titleText">
              <Button appearance="transparent" icon={<ArrowLeft32Regular />}>
                <Title3>{`Scorecard: ${scorecardDetail?.scorecardId}`}</Title3>
              </Button>
            </div>
            <Button
              icon={<ReceiptSearch20Regular />}
              onClick={handleFlightReview}
            >
              Create flight review request
            </Button>
          </div>
          <div className={styles.meta}>
            <div className={styles.metaColumn}>
              <Field
                className={styles.metaItem}
                label={<Body1Strong>Scorecard status</Body1Strong>}
              >
                {scorecardDetail.scorecardState ? (
                  <StatusTag label={scorecardDetail.scorecardState} />
                ) : (
                  <></>
                )}
              </Field>
              <Field
                className={styles.metaItem}
                label={<Body1Strong>Experiment name</Body1Strong>}
              >
                {scorecardDetail.experimentId ? (
                  <Link
                    to={`https://exp.microsoft.com/a/feature/${scorecardDetail.experimentId}?workspaceId=${Exp_Workspace_Id}`}
                    target="_blank"
                  >
                    <FluentLink>
                      {scorecardDetail.experimentName}
                      <Button
                        appearance="transparent"
                        icon={<Open20Regular />}
                      />
                    </FluentLink>
                  </Link>
                ) : (
                  <Text>{scorecardDetail.experimentName}</Text>
                )}
              </Field>
              <Field
                className={styles.metaItem}
                label={<Body1Strong>Experiment group</Body1Strong>}
              >
                <Text>{scorecardDetail.managementGroup}</Text>
              </Field>
            </div>
            <div className={styles.metaColumn}>
              <Field
                className={styles.metaItem}
                label={<Body1Strong>Scorecard ring</Body1Strong>}
              >
                <Text>
                  {scorecardDetail.stageName}{" "}
                  {scorecardDetail.experimentStepName}
                </Text>
              </Field>
              <Field
                className={styles.metaItem}
                label={<Body1Strong>Offline evaluation history</Body1Strong>}
              >
                <span>
                  {offlineExperiment ? (
                    <Link to={`/detail/${offlineExperiment.ExternalId}`}>
                      <FluentLink>{offlineExperiment.Name}</FluentLink>
                    </Link>
                  ) : (
                    <Text italic={true} align="center">
                      {<Warning20Filled className={styles.warningIcon} />}
                      No offline job connected, please select from list
                    </Text>
                  )}
                  <Button
                    className={styles.inlineButton}
                    appearance="transparent"
                    icon={<Edit20Regular />}
                    onClick={() => {
                      setConnectDialogOpen(true);
                    }}
                  />
                  <TeachingPopover
                    appearance="brand"
                    defaultOpen={!offlineExperiment}
                    positioning="after-top"
                    withArrow={true}
                    closeOnIframeFocus={false}
                  >
                    <TeachingPopoverTrigger>
                      <i style={{ visibility: "hidden" }} />
                    </TeachingPopoverTrigger>
                    <TeachingPopoverSurface>
                      <TeachingPopoverHeader className={styles.tipsTitle}>
                        Reminder
                      </TeachingPopoverHeader>
                      <TeachingPopoverBody>
                        <TeachingPopoverTitle>
                          Set up offline evaluation history
                        </TeachingPopoverTitle>
                        <div>
                          No offline job connected, please select from list
                        </div>
                      </TeachingPopoverBody>
                    </TeachingPopoverSurface>
                  </TeachingPopover>
                </span>
              </Field>
              <Field
                className={styles.metaItem}
                label={<Body1Strong>Time range</Body1Strong>}
              >
                <Text>
                  {formatDatetime(scorecardDetail.timeRangeStart ?? "")}
                  {" - "}
                  {formatDatetime(scorecardDetail.timeRangeEnd ?? "")}
                </Text>
              </Field>
            </div>
          </div>
          {scorecardDetail.scorecardId && (
            <ExperimentScorecardMetrics
              scorecardId={scorecardDetail.scorecardId}
              stepId={scorecardDetail.experimentStepId}
            />
          )}
        </div>
      ) : (
        <></>
      )}
      {connectDialogOpen && (
        <ExperimentIterationDialog
          isOpen={connectDialogOpen}
          defaultSelectedId={offlineExperiment?.ExternalId}
          onDismiss={onConnectChange}
        />
      )}
    </>
  );
});

import {
  Body1,
  Button,
  Dialog,
  DialogActions,
  DialogBody,
  DialogContent,
  DialogSurface,
  DialogTitle,
  Field,
  Input,
} from "@fluentui/react-components";
import { computed } from "mobx";
import { observer } from "mobx-react-lite";
import React from "react";
import { useToast } from "../../../../components/Wrappers/ToasterProvider";
import type { FeatureSettingsDialogContributionProps } from "../../../../contribution/FeatureSettingsContribution";
import {
  getTestAccountPasswordName,
  getTokenName,
} from "../../../../helpers/accountHelper";
import { telemetryHelper } from "../../../../helpers/telemetryHelper";
import { uploadToken } from "../../actions/uploadToken";
import type { FakeTenantUploadTokenType } from "../../models/Types";

type UploadFakeTenantInfoDialogProps =
  FeatureSettingsDialogContributionProps & {
    type: FakeTenantUploadTokenType;
  };

export const UploadFakeTenantInfoDialog = observer(
  (props: UploadFakeTenantInfoDialogProps) => {
    const [fakeTenantAccountName, setFakeTenantAccountName] =
      React.useState<string>("");
    const [secretValue, setSecretValue] = React.useState<string>("");
    const toast = useToast();

    const computedSecretNameMessage = computed(() => {
      if (fakeTenantAccountName.trim() === "") {
        return "account can not be empty";
      }
      if (fakeTenantAccountName.includes("@microsoft.com")) {
        return "account should be test tenant account";
      }
      return undefined;
    });

    const computedSecretValueMessage = computed(() => {
      if (secretValue.trim() === "") {
        return `${props.type} value can not be empty`;
      }
      return undefined;
    });

    const computedSecretNamae = computed(() => {
      switch (props.type) {
        case "3S refresh token":
          return getTokenName(fakeTenantAccountName, "3s");
        case "password":
          return getTestAccountPasswordName(fakeTenantAccountName);
      }
    });

    const handleUploadToken = () => {
      toast.onToastStart("Uploading token...");

      telemetryHelper.logUserActionEvent("SetToken", {
        inputType: props.type,
        source: "UploadFakeTenantInfoDialog",
      });

      uploadToken(
        props.type,
        computedSecretNamae.get(),
        secretValue,
        () => {
          telemetryHelper.logUserActionEvent("SetTokenSuccess", {
            inputType: props.type,
            source: "UploadFakeTenantInfoDialog",
          });

          toast.onToastSuccess("Token uploaded successfully");
          props.close();
        },
        (error) => {
          telemetryHelper.logUserActionEvent("SetTokenFailure", {
            inputType: props.type,
            message: error.message,
            source: "UploadFakeTenantInfoDialog",
          });

          toast.onToastFailure(
            `Token upload failed with message: ${error.message}`,
          );
          props.close();
        },
      );
    };

    const computedTitle = computed(() => {
      switch (props.type) {
        case "3S refresh token":
          return "Upload Test Tenant 3S Refresh Token";
        case "password":
          return "Upload Test Tenant Password";
      }
    });

    const onDialogClose = () => {
      props.close();
    };

    React.useEffect(() => {
      if (props.isOpen) {
        setFakeTenantAccountName("");
        setSecretValue("");
      }
    }, [props.isOpen]);

    return (
      <Dialog
        modalType="alert"
        open={props.isOpen}
        onOpenChange={() => {
          props.close();
        }}
      >
        <DialogSurface>
          <DialogBody>
            <DialogTitle>{computedTitle.get()}</DialogTitle>
            <DialogContent>
              <Field
                required
                label={
                  <Body1>{"Please provide your test tenant account: "}</Body1>
                }
                validationState={
                  computedSecretNameMessage.get() ? "error" : "success"
                }
                validationMessage={computedSecretNameMessage.get()}
              >
                <Input
                  style={{ width: "100%" }}
                  size="medium"
                  value={fakeTenantAccountName}
                  onChange={(_, data) => {
                    setFakeTenantAccountName(data.value);
                  }}
                />
              </Field>

              <Field
                required
                label={
                  <>
                    <Body1>{`You can input your ${props.type} here.`}</Body1>
                    {fakeTenantAccountName && (
                      <>
                        <br />
                        <Body1>{`This ${props.type} will be stored as secret with the name below in Azure Key Vault.`}</Body1>
                        <br />
                        <strong>{`${computedSecretNamae.get()}`}</strong>
                      </>
                    )}
                  </>
                }
                validationState={
                  computedSecretValueMessage.get() ? "error" : "success"
                }
                validationMessage={computedSecretValueMessage.get()}
              >
                <Input
                  style={{ width: "100%" }}
                  size="medium"
                  value={secretValue}
                  onChange={(_, data) => {
                    setSecretValue(data.value);
                  }}
                />
              </Field>
            </DialogContent>

            <DialogActions>
              <Button
                disabled={
                  computedSecretNameMessage.get() !== undefined ||
                  computedSecretValueMessage.get() !== undefined
                }
                onClick={() => {
                  handleUploadToken();
                }}
                appearance="primary"
              >
                Confirm
              </Button>
              <Button onClick={onDialogClose} appearance="secondary">
                Close
              </Button>
            </DialogActions>
          </DialogBody>
        </DialogSurface>
      </Dialog>
    );
  },
);

import { computed } from "mobx";
import { isJobSupportShare, isRestrictedSharingUser } from "sydneyeval-shared";
import { getAppEnv } from "../../../helpers/appEnvHelper";
import {
  isUserProfileErrorResponse,
  isUserProfileSuccessResponse,
} from "../../../models/UserProfileResponse";
import { contributionStore } from "../../../store/contributionStore";
import { getUserAlias } from "../../HumanCorrelationStudy/components/utils/utilities";
import { jobShareStore } from "../store/jobShareStore";
import { jobStore } from "../store/jobStore";
import { resultStore } from "../store/resultStore";

export const getShareLink = computed(() => {
  const id = resultStore.resultJob?.ID;
  const host = getAppEnv().webEndpoint;
  if (id === undefined) {
    return `${host}/jobs`;
  }
  return `${host}/job/${id}`;
});

export const getIsRestrictedSharing = computed(() => {
  const userAlias = getUserAlias();
  const userProfile = jobShareStore.userProfile;
  if (userProfile === undefined) {
    return false;
  }
  if (isUserProfileErrorResponse(userProfile)) {
    return true;
  }
  if (isUserProfileSuccessResponse(userProfile)) {
    return isRestrictedSharingUser(userAlias, userProfile);
  }
  return true;
});

export const getCurrentScenario = () => {
  const path = contributionStore.currentPath;
  if (path.includes("create")) {
    return "create";
  }
  if (path.includes("jobs") || path === "/") {
    return "list";
  }
  if (path.includes("job") || path.includes("detail")) {
    return "job";
  }
  return "other";
};

export const getSharedRecordId = computed(() => {
  if (getCurrentScenario() === "job" || getCurrentScenario() === "list") {
    return resultStore.resultJob?.ID.toString();
  }
  return undefined;
});

export const getSharedJobName = computed(() => {
  if (getCurrentScenario() === "job" || getCurrentScenario() === "list") {
    return resultStore.resultJob?.JobName;
  }
  if (getCurrentScenario() === "create") {
    return jobStore.jobName;
  }
  return undefined;
});

export const checkHasSharePermission = computed(() => {
  if (getCurrentScenario() === "job") {
    return resultStore.resultJob?.Properties?.HasSharePermission === true;
  }
  if (getCurrentScenario() === "create") {
    const isShareJob = isJobSupportShare(
      jobStore.configurationJsonString,
      jobStore.selectedTemplate?.Type ?? "",
      jobStore.selectedEvaluationType,
    );
    return isShareJob;
  }
  return false;
});

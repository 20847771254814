import type {
  AxiosRequestConfig,
  Method,
  RawAxiosRequestHeaders,
  ResponseType,
} from "axios";
import axios from "axios";
import type { ErrorCode, TypeGuard } from "sydneyeval-shared";
import { ApiErrorResponse, ApiSuccessResponse } from "../models/ApiResponse";
import { getAppEnv } from "./appEnvHelper";
import { getRandomUUID } from "./getRandomUUID";
import { getBasicHeader } from "./headerHelper";
import { retryable } from "./retryable";
import type { TelemetryDataBag } from "./telemetryHelper";

// Define a new Error extends Error
export class ApiError extends Error {
  // Define a new property code
  code: ErrorCode | undefined;

  constructor(message: string | undefined, code: ErrorCode | undefined) {
    super(message);
    this.name = "ApiError";
    this.code = code;
  }
}

axios.defaults.withCredentials = true;

type CreateRequestProps<T, V> = {
  api: string;
  requestData?: T;
  typeGuard: TypeGuard<V>;
  host?: string;
  method?: Method;
  scenarioId?: string;
  headers?: RawAxiosRequestHeaders;
  responseType?: ResponseType;
  dataBag?: TelemetryDataBag;
  retryOptions?: { retryCount?: number };
};

const apiParser = <T>(typeGuard: TypeGuard<T>): TypeGuard<T> => {
  return (value: unknown, trace: string) => {
    const errorResponse = ApiErrorResponse(value, `${trace}: ApiErrorResponse`);
    if (errorResponse) {
      throw new ApiError(errorResponse.message, errorResponse.code);
    }
    const successResponse = ApiSuccessResponse(
      value,
      `${trace}: ApiSuccessResponse`,
    );
    return typeGuard(successResponse.data, trace);
  };
};

// Create General SEVAL Server JSON API request
export const createRequest = <T, V>(
  props: CreateRequestProps<T, V>,
): Promise<V> => {
  return createRequestInternal({
    ...props,
    typeGuard: apiParser(props.typeGuard),
  });
};

// Create request for HCS
export const createRequestForHCS = <T, V>(props: CreateRequestProps<T, V>) => {
  return createRequestInternal({
    ...props,
    dataBag: { skipSuccessRate: true },
  });
};

const createRequestInternal = <T, V>(
  props: CreateRequestProps<T, V>,
): Promise<V> => {
  const {
    api,
    requestData,
    typeGuard,
    host,
    method,
    scenarioId,
    headers,
    responseType,
    dataBag,
    retryOptions,
  } = props;

  const url = host ? `${host}${api}` : `${getAppEnv().apiEndpoint}${api}`;

  const telemetryInfo = {
    ...dataBag,
    api: api.split("?")[0],
    url,
    scenarioId: scenarioId ?? getRandomUUID(),
  };

  return retryable(
    async () => {
      const config: AxiosRequestConfig = {
        headers: headers ?? (await getBasicHeader()),
        responseType,
      };

      const getRequestPromise = () => {
        switch (method) {
          case "GET":
            return axios.get(url, config);
          case "POST":
            return axios.post(url, requestData, config);
          case "DELETE":
            return axios.delete(url, config);
          default:
            return requestData
              ? axios.post(url, requestData, config)
              : axios.get(url, config);
        }
      };

      return getRequestPromise().then((res) => {
        return typeGuard(res.data, api);
      });
    },
    telemetryInfo,
    retryOptions,
  );
};
